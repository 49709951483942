import { isIndexed, mkNode, removeChildren, removeNode } from '@p4b/utils';
import { LocalData, Structure } from '@p4b/exam-service';
import {
    Question, QuestionContext, Expr, ExprObject, ExprVal, ExprRef, ExprEq,
    ExprGt, ExprLt, ExprGe, ExprLe, ExprAnd, ExprOr, ExprXor, ExprNot,
    answerTypes,
    QuestionManifest
} from '@p4b/question-base';
import { ComponentDetails, MeetingEvent } from '@p4b/meeting';
import { ThumbnailViewer } from '@p4b/thumbnail-viewer';
import { Lightbox } from '@p4b/lightbox'
import ResizeObserver from 'resize-observer-polyfill';
import { translate, initTranslation } from '@p4b/utils-lang';
import { ThumbnailObserver } from '@p4b/thumbnail-base';
import { confirmModal } from "@p4b/modal-dialog";
import { dbGet } from '@p4b/utils-db';
import { ImageCmdObserver, ImageCommand, isImageCommand, SHARE_MODE } from '@p4b/image-viewer';
import { configInfoToolPanel } from './exam-accessibility';

declare global {
    interface Performance {
        memory?: {
            totalJSHeapSize: number,
            usedJSHeapSize: number,
            jsHeapSizeLimit: number,
        }
    }
}

function logMemoryUsed(tag = '') {
    //eslint-disable-next-line compat/compat
    const memory = window?.performance?.memory;
    if (memory && memory.usedJSHeapSize) {
        console.log(`${tag?`${tag} `:''}MEMORY ${memory.usedJSHeapSize / 1048576} MB`);
    } else if (tag) {
        console.log(`${tag} MEMORY API not available`);
    }
}

function isExprVal(x: ExprObject): x is ExprVal {
    return (x as ExprVal).value != undefined;
}

function isExprRef(x: ExprObject): x is ExprRef {
    return (x as ExprRef).backend_id != undefined;
}

function isExprEq(x: Expr): x is ExprEq {
    return (x as ExprEq).eq != undefined && (x as ExprEq).eq.length == 2;
}

function isExprGt(x: Expr): x is ExprGt {
    return (x as ExprGt).gt != undefined && (x as ExprGt).gt.length == 2;
}

function isExprLt(x: Expr): x is ExprLt {
    return (x as ExprLt).lt != undefined && (x as ExprLt).lt.length == 2;
}

function isExprGe(x: Expr): x is ExprGe {
    return (x as ExprGe).ge != undefined && (x as ExprGe).ge.length == 2;
}

function isExprLe(x: Expr): x is ExprLe {
    return (x as ExprLe).le != undefined && (x as ExprLe).le.length == 2;
}

function isExprAnd(x: Expr): x is ExprAnd {
    return (x as ExprAnd).and != undefined && (x as ExprAnd).and.length > 1;
}

function isExprOr(x: Expr): x is ExprOr {
    return (x as ExprOr).or != undefined && (x as ExprOr).or.length > 1;
}

function isExprXor(x: Expr): x is ExprXor {
    return (x as ExprXor).xor != undefined && (x as ExprXor).xor.length > 1;
}

function isExprNot(x: Expr): x is ExprNot {
    return (x as ExprNot).not != undefined;
}

async function evalExprString(expr: ExprObject, find: (id: number) => Promise<string>): Promise<string> {
    if (isExprRef(expr)) {
        return await find(expr.backend_id);
    } else if (isExprVal(expr)) {
        return expr.value;
    }
    return '';
}

export async function evalExprBool(expr: Expr, find: (id: number) => Promise<string>): Promise<boolean> {
    if (isExprEq(expr)) {
        return await evalExprString(expr.eq[0], find) === await evalExprString(expr.eq[1], find);
    } else if (isExprGt(expr)) {
        return await evalExprString(expr.gt[0], find) > await evalExprString(expr.gt[1], find);
    } else if (isExprLt(expr)) {
        return await evalExprString(expr.lt[0], find) < await evalExprString(expr.lt[1], find);
    } else if (isExprGe(expr)) {
        return await evalExprString(expr.ge[0], find) >= await evalExprString(expr.ge[1], find);
    } else if (isExprLe(expr)) {
        return await evalExprString(expr.le[0], find) <= await evalExprString(expr.le[1], find);
    } else if (isExprAnd(expr)) {
        let x = await evalExprBool(expr.and[0], find);
        for (let i = 1; i < expr.and.length; ++i) {
            x = x && await evalExprBool(expr.and[i], find);
        }
        return x;
    } else if (isExprOr(expr)) {
        let x = await evalExprBool(expr.or[0], find);
        for (let i = 1; i < expr.or.length; ++i) {
            x = x || await evalExprBool(expr.or[i], find);
        }
        return x;
    } else if (isExprXor(expr)) {
        let x = await evalExprBool(expr.xor[0], find);
        for (let i = 1; i < expr.xor.length; ++i) {
            x = !x != !await evalExprBool(expr.xor[i], find);
        }
        return x;
    } else if (isExprNot(expr)) {
        return !await evalExprBool(expr.not, find);
    }
    return false;
}

/**
 * QuestionViewer custom HTML element.
 */
export class QuestionViewerElement extends HTMLElement implements ThumbnailObserver, ImageCmdObserver {
    private page: HTMLDivElement;
    private question: HTMLDivElement;
    private questionInner: HTMLDivElement;
    private questionTitle: HTMLHeadingElement;
    private stem: HTMLDivElement;
    private answers: HTMLDivElement;
    private answersInner: HTMLDivElement;
    private answerTitle: HTMLHeadingElement;
    private answerText: Text;
    private answerPanel: HTMLDivElement;

    private context?: QuestionContext;
    public components = new Array<Question>();
    private componentMap= new Map<number, number>();
    //private popout?: Window;
    //private meeting: MeetingViewer;
    private thumbnails?: ThumbnailViewer;
    private lightbox: Lightbox;
    private resizeObserver: ResizeObserver;
    private isOsce = false;
    private isRosce = false;
    private isCandidate = false;
    private startTime = 0;
    private questionIndex = -1;
    private previousTimeOnQuestion = 0;
    private validityExpression?: PractiqueNet.ExamJson.Definitions.Expr;
    private round?: number;

    public async initTranslation() {
        await initTranslation();
    }

    public getExamType(): ('osce'|'written') {
        return (this.isOsce) ? 'osce' : 'written';
    }

    private readonly handleScroll = async (event: Event) => {
        if (event.target instanceof Element) {
            await new Promise(resolve => window.requestAnimationFrame(resolve));
            const {scrollTop, scrollLeft, scrollHeight, clientHeight} = event.target
            , atTop = scrollTop === 0
            , beforeTop = 1
            , atBottom = scrollTop === scrollHeight - clientHeight
            , beforeBottom = scrollHeight - clientHeight - 1
            ;
            if (atTop) {
                event.target.scrollTo(scrollLeft, beforeTop);
            } else if (atBottom) {
                event.target.scrollTo(scrollLeft, beforeBottom);
            }
        }
    }

    private readonly handleResize = (entries: ResizeObserverEntry[]): void => {
        let lastLightbox = undefined;
        let lastQuestion = undefined;
        let lastAnswers = undefined;
        for (const entry of entries) {
            if (entry.target === this.context?.parent) {
                lastLightbox = entry;
            } else if (entry.target === this.question) {
                lastQuestion = entry;
            } else if (entry.target === this.answers) {
                lastAnswers = entry;
            }
        }

        if (lastLightbox) {
            this.lightbox.setHeight(lastLightbox.contentRect.height);
        }
        if (lastQuestion) {
            this.question.dispatchEvent(new Event('scroll'));
        }
        if (lastAnswers) {
            this.answers.dispatchEvent(new Event('scroll'));
        }
    };

    public constructor() {
        super();
        this.page = mkNode('div', {className: 'qna-panel'});
        this.question = mkNode('div', {className: 'question config-user000-background config-user000aaa-text', parent: this.page});
        this.questionInner = mkNode('div', {className: 'question-inner', parent: this.question});
        this.lightbox = new Lightbox();
        this.lightbox.addCommandObserver(this);
        this.questionTitle = mkNode('h2', {className: `title ${configInfoToolPanel}`, parent: this.questionInner, children: [
            mkNode('text', {text: 'Question'}),
        ]});
        this.stem = mkNode('div', {className: 'stem break-word', parent: this.questionInner, attrib: {role: 'region', 'aria-live': 'polite'}});
        this.answers = mkNode('div', {className: 'answers', parent: this.page});
        this.answersInner = mkNode('div', {className: 'answers-inner', id: 'answers-inner', parent: this.answers});
        this.answerTitle = mkNode('h2', {className: `answer-title ${configInfoToolPanel}`, parent: this.answersInner});
        this.answerText = mkNode('text', {text: '\u00a0', parent: this.answerTitle});
        this.answerPanel = mkNode('div', {className: 'answer-panel', parent: this.answersInner});
        this.question.scrollTop = 1;
        this.answers.scrollTop = 1;
        this.resizeObserver = new ResizeObserver(this.handleResize);
    }

    public connectedCallback() {
        console.debug('QUESTION-VIEWER CONNECTED');
        if (!this.parentElement) {
            throw new DOMException('no parent element', 'InvalidStateError');
        }
        if (!this.context) {
            this.args({parent: this.parentElement});
            if (!this.context) {
                throw new DOMException('context not set', 'InvalidStateError');
            }
        }
        this.context.candidateId = this.getAttribute('candidate-id') ?? '?';
        this.context.component = Number(this.getAttribute('component'));
        this.context.parent = this.parentElement
        this.lightbox.args({
            fullscreenParent: this.context.fullscreenParent,
            scrollContainer: this.question,
            resources: this.context.resources,
            navigation: this.context.navigation,
            meta: this.context.meta,
            controlPanel: this.context.controlPanel,
            gridControlPanel: this.context.meetingBar,
            //component: this.context.component,
        }, this.questionInner);
        this.style.display = 'contents';
        this.appendChild(this.page);
        this.question.addEventListener('visibility', this.handleVisibility);
        this.question.addEventListener('scroll', this.handleScroll);
        this.answers.addEventListener('scroll', this.handleScroll);
        this.resizeObserver.observe(this.context.parent);
        this.resizeObserver.observe(this.question);
        this.resizeObserver.observe(this.answers);
    }

    public disconnectedCallback() {
        console.debug('QUESTION-VIEWER DISCONNECTED');
        this.destroy();
    }

    /**
     * Set properties for QuestionViewer.
     */
    public args({
        meta,
        component,
        factorDetails,
        fullscreenParent,
        parent = this,
        candidateId = '?',
        controlPanel = {
            add: () => undefined,
        },
        meetingBar = {
            add: () => undefined,
        },
        notifications = {
            warning: () => undefined,
            none: () => undefined,
        },
        meeting = {
            setFactor: async () => undefined,
            setInterview: async (): Promise<boolean> => false,
            getRoles: () => new Map(),
            requestStatus: () => undefined,
            sendStatus: () => undefined,
            sendTime: () => undefined,
            sendCommandMessage: () => undefined,
        },
        questions = {
            getQuestion: async (index: number) => await dbGet<QuestionManifest>('questions', index),
        },
        resources = {
            getImageBegin: async (): Promise<void> => undefined,
            getImageFrame: async (): Promise<ArrayBuffer|undefined> => undefined,
            getImageEnd: async (): Promise<void> => undefined,
        },
        responses = {
            saveAnswer: async (): Promise<void> => undefined,
            loadAnswer: async (): Promise<LocalData|undefined> => undefined,
            setVisible: (): void => undefined,
            setFlag: async (): Promise<void> => undefined,
            getFlag: async (): Promise<boolean> => false,
            setValid: (): void => undefined,
            setInvalid: (): void => undefined,
            getValid: (): boolean => true,
            getDisplayId: (): string|undefined => undefined,
        },
        timing = {
            getTimers: () => ({
                set: () => undefined,
                get: () => 0,
                start: () => true,
                stop: () => true,
            }),
        },
        navigation = {
            setNavigating: () => undefined,
            getNavigating: () => false,
        },
    } : Partial<QuestionContext>) {
        this.context = {
            controlPanel, meetingBar, notifications, meeting,
            questions, resources, responses, timing, navigation, parent,
            fullscreenParent, candidateId, meta, factorDetails, component
        };

    }

    private evalExprBool(expr: Expr): boolean {
        if (isExprEq(expr)) {
            return this.evalExprString(expr.eq[0]) === this.evalExprString(expr.eq[1]);
        } else if (isExprGt(expr)) {
            return this.evalExprString(expr.gt[0]) > this.evalExprString(expr.gt[1]);
        } else if (isExprLt(expr)) {
            return this.evalExprString(expr.lt[0]) < this.evalExprString(expr.lt[1]);
        } else if (isExprGe(expr)) {
            return this.evalExprString(expr.ge[0]) >= this.evalExprString(expr.ge[1]);
        } else if (isExprLe(expr)) {
            return this.evalExprString(expr.le[0]) <= this.evalExprString(expr.le[1]);
        } else if (isExprAnd(expr)) {
            let x = this.evalExprBool(expr.and[0]);
            for (let i = 1; i < expr.and.length; ++i) {
                x = x && this.evalExprBool(expr.and[i]);
            }
            return x;
        } else if (isExprOr(expr)) {
            let x = this.evalExprBool(expr.or[0]);
            for (let i = 1; i < expr.or.length; ++i) {
                x = x && this.evalExprBool(expr.or[i]);
            }
            return x;
        } else if (isExprXor(expr)) {
            let x = this.evalExprBool(expr.xor[0]);
            for (let i = 1; i < expr.xor.length; ++i) {
                x = !x != !this.evalExprBool(expr.xor[i]);
            }
            return x;
        } else if (isExprNot(expr)) {
            return !this.evalExprBool(expr.not);
        }
        return false;
    }

    private evalExprString(expr: ExprObject): string {
        if (isExprRef(expr)) {
            const x = this.componentMap.get(expr.backend_id);
            if (x != undefined) {
                return this.components[x].getValue();
            }
        } else if (isExprVal(expr)) {
            return expr.value;
        }
        return '';
    }

    private readonly updateVisibility = (): void => {
        for (const component of this.components) {
            if (component.visibilityExpression) {
                component.setVisible(this.evalExprBool(component.visibilityExpression));
            }
        }
        this.updateValidity();
    }

    private readonly handleVisibility = (e: Event): void => {
        if (e instanceof CustomEvent) {
            this.question.style.display = (e.detail.visibility || this.thumbnails || this.stem.innerHTML || this.isOsce) ? 'block' : 'none';
        }
    }

    private updateValidity(): void {
        for (const component of this.components) {
            if (component?.mandatory && component.getValue() !== '') {
                component.updateValidity(true);
            }
        }
    }

    private async checkValidity(): Promise<boolean> {
        if (!this.context) {
            throw new DOMException('context not defined', 'InvalidStateError');
        }
        const invalid = [];
        for (const component of this.components) {
            const inv = (component?.mandatory && component.getValue() === '');
            component.updateValidity(!inv);
            if (inv) {
                invalid.push(component.displayId ?? '');
                this.context.responses.setInvalid(component.qno, component.ano);
            }
        }
        return invalid.length <= 0 || (this.isOsce && await confirmModal(translate('MARKSHEET_INVALID', {invalid: invalid.join(' ')})));
    }

    private disableCheckpoint = false;

    public async checkpointQuestion(): Promise<void> {
        if (!this.context) {
            throw new DOMException('context not defined', 'InvalidStateError');
        }
        if (this.disableCheckpoint) {
            return;
        }
        await this.context.responses.saveAnswer({qno: this.questionIndex, ano: -1}, {
            timeOnQuestion: this.previousTimeOnQuestion + this.context.timing.getTimers().get() - this.startTime,
            nextQuestion: this.questionIndex,
            extra: {connectedTime: this.context.timing.getTimers().get('connected')},
        });
    }

    // *FIXME* Get question start time from server saved question change data.
    public async setQuestion({structure, language, time, notify = true, forced, validate = this.context?.meta?.disableBackwardsNavigation}: {
        structure?: Structure,
        language: number,
        time: number,
        notify?: boolean,
        forced: boolean,
        validate?: boolean,
    }): Promise<boolean> {
        if (!this.context) {
            throw new DOMException('context not defined', 'InvalidStateError');
        }
        if (validate && !forced && !await this.checkValidity()) {
            return false;
        }
        try {
            if (this.questionIndex >= 0 && notify) {
                // If there was a previous question loaded, store the timeOnQuestion as we are navigating away.
                await this.context.responses.saveAnswer({qno: this.questionIndex, ano: -1}, {
                    timeOnQuestion: this.previousTimeOnQuestion + time - this.startTime,
                    nextQuestion: structure?.question,
                    extra: {connectedTime: this.context.timing.getTimers().get('connected')},
                });
            }

            // Destroy resources of previous question.
            //console.debug('close lightbox');
            await this.lightbox.closeAll();

            try {
                this.disableCheckpoint = true; // don't save again on meeting end as we have just saved above
                //this.setResourceStatus({released: false});
                //this.context.meeting.sendStatus();
                //console.debug('destroy meetings');
                //if (this.meeting) {
                //    await this.meeting.destroy();
                //    this.meeting = undefined;
                //}
            } finally {
                this.disableCheckpoint = false;
            }

            if (this.thumbnails) {
                this.thumbnails.destroy();
                this.thumbnails = undefined;
            }

            // Load new question and previously saved timeOnQuestion before destroying anything.
            const backendQid = structure && structure.backendQid[language ?? 0];
            const question = (structure && backendQid !== undefined) ? (await this.context.questions.getQuestion(structure.backendQid[language ?? 0])) : undefined;
            //console.warn('QUESTION', question);
            const savedTime = structure && await this.context.responses.loadAnswer(structure.question, -1)
            if (savedTime && savedTime.timeOnQuestion) {
                this.previousTimeOnQuestion = savedTime.timeOnQuestion
            } else {
                this.previousTimeOnQuestion = 0;
            }
            if (structure?.round == undefined || this.round == undefined || structure.round != this.round) {
                console.debug('CONNECTION_TIME LOAD');
                if (savedTime && isIndexed(savedTime.extra) && typeof savedTime.extra.connectedTime === 'number') {
                    this.context.timing.getTimers().set('connected', savedTime.extra.connectedTime);
                } else {
                    this.context.timing.getTimers().set('connected', 0);
                }
            }

            this.questionTitle.innerHTML = '&nbsp;'
            removeChildren(this.stem);
            for (const component of this.components) {
                component.destroy();
            }
            this.components = [];
            this.componentMap.clear();
            removeChildren(this.answerPanel);

            // If no new question to load, finish here.
            if (!structure) {
                return false;
            }

            // Load new question.
            const pos = structure.question;
            const factor = structure.factor;
            const interview = structure.interview;

            this.startTime = time;
            this.questionIndex = pos;
            this.isOsce = structure.room != null;
            this.isRosce = interview !== undefined && factor !== undefined;
            this.isCandidate = this.context.component === ComponentDetails.ROLE_CANDIDATE;
            this.round = structure.round;

            //console.debug('QUESTION LOADED', question);

            const loaders = [];

            let title = '';
            if ((structure.round != null) || (structure.room != null) || (structure.circuit != null)) {
                const ts = [];
                if (structure.round != null) {
                    ts.push(`${translate('TITLE_ROUND')} <span style="font-weight:700;">${structure.round}</span>`);
                }
                if (structure.room != null) {
                    ts.push(`${translate('TITLE_STATION')} <span style="font-weight:700;">${structure.room}</span>`);
                }
                if (structure.circuit != null) {
                    ts.push(`${translate('TITLE_CIRCUIT')} <span style="font-weight:700;">${structure.circuit}</span>`);
                }
                title += ts.join(', ');
            } else {
                title += '<span style="font-weight:700;">' + (structure.displayQstNumber/*1 + pos*/) + '</span>'
            }
            if (question && question.manifest.title && this.context.meta?.show_question_title) {
                title += ': ' + question.manifest.title;
            }
            if (structure.case && structure.ofCases && structure.ofCases > 1) {
                this.questionTitle.innerHTML = `<div style="display:flex"><span style="flex:1">${title}</span><span>${translate('TITLE_CASE', {n: structure.case, m:structure.ofCases})}</span></div>`;
            } else {
                this.questionTitle.innerHTML = title;
            }

            if (question && (question.manifest.stem || question.images.length > 0) || structure.round != null) {
                if (question && question.manifest.stem) {
                    this.stem.innerHTML = question.manifest.stem;
                    this.stem.style.display = 'block';
                } else {
                    this.stem.innerHTML = '';
                    this.stem.style.display = 'none';
                }
                if (question && question.images.length > 0) {
                    this.thumbnails = new ThumbnailViewer({
                        fullscreenParent: this.context.fullscreenParent,
                        scrollContainer: this.question,
                        sizeReference: this.page,
                        resources: this.context.resources,
                        navigation: this.context.navigation,
                        isRemoteShowHide: this.isRosce && this.context.component !== ComponentDetails.ROLE_EXAMINER,
                        disableSharedManipulation: this.context.meta?.disableSharedManipulation ?? true,
                        component: this.context.component ?? 0,
                    }, this.questionInner, question.images, this.lightbox);
                    this.thumbnails.disabled((!this.context.meta?.disableResourceLocking) && (question.manifest.answers.length < 1) && (structure.interview !== undefined));
                    loaders.push(this.thumbnails.loadResources(question.thumbnails));
                }
                this.question.style.display = 'block';
            } else {
                this.question.style.display = 'none';
            }

            title = '';
            if (structure.room != null) {
                if (factor !== undefined) {
                    if (structure.interview) {
                        title += translate('TITLE_CONNECT_TO', {factor});
                    } else {
                        title += '<b>' + factor + '</b>';
                    }
                    const details = this.context.factorDetails?.[factor];
                    if (details && (details.family_name || details.given_name)) {
                        title += ': ';
                        if (details.family_name) {
                            title += details.family_name;
                        }
                        if (details.family_name && details.given_name) {
                            title += ', ';
                        }
                        if (details.given_name) {
                            title += details.given_name;
                        }
                    }
                } else if (this.isCandidate) {
                    title += translate('REST_STATION');
                } else {
                    title += translate('NO_CANDIDATE');
                }
                this.answers.style.display = 'block'
            } else if (question && question.manifest.answers.length > 0) {
                title = translate('ANSWER_TITLE');
                this.answers.style.display = 'block'
            } else {
                this.answers.style.display = 'none';
            }
            this.answerTitle.innerHTML = title;

            if (question) {
                if (question.manifest.answers.length > 0) {
                    this.validityExpression = question.manifest.valid;
                    const frag = document.createDocumentFragment();
                    let i = 0;
                    for (let j = 0; j < question.manifest.answers.length; ++j) {
                        const answer = question.manifest.answers[j];
                        for (const t of answerTypes) {
                            if (t.isThis(answer, question.manifest.answers.length, this.context.meta, this.isOsce)) {
                                //console.warn('QUESTION COMPONENT', question);
                                const component = t.makeAnswer(
                                    pos,
                                    this.context,
                                    this.updateVisibility,
                                    question,
                                    answer,
                                    frag,
                                    j,
                                    this.lightbox,
                                    this.isRosce && this.context.component !== ComponentDetails.ROLE_EXAMINER,
                                    this.isOsce,
                                );
                                if (this.isReadOnly) {
                                    component.setReadOnly(true);
                                }
                                this.componentMap.set(answer.backend_id, j);
                                if (answer.backend_id >= 0) {
                                    const answerResources = question.answersResources[i++];
                                    if (answerResources) {
                                        loaders.push(component.loadResources(answerResources.thumbnails));
                                    }
                                }
                                this.components.push(component);
                                break;
                            }
                        }
                    }
                    this.updateVisibility();
                    this.answerPanel.appendChild(frag);
                    loaders.push(this.loadFlags());
                    loaders.push(this.loadAnswers());
                }
            }

            console.warn('INTERVIEW', interview);
            if (interview) { // (this.isRosce && interview) {
                this.thumbnails?.addObserver(this);
                this.resourceStatus.forEach((value, key) => {
                    this.setResourceStatusOnly({id: key, released: value});
                });
                await this.context.meeting.setFactor(factor)
                await this.context.meeting.setInterview(this.context.meta?.answer_aes_key, this.context.candidateId, interview);
                await this.updateStatus(this.context.meeting.getRoles());
            } else {
                await this.context.meeting.setInterview();
            }
            await Promise.all(loaders);
            return true;
        } finally {
            for (const component of this.components) {
                component.loadingComplete();
            }
            logMemoryUsed(`QUESTION ${this.questionIndex} LOADED`);
            this.context.parent.scrollTop = 1;
            this.question.scrollTop = 1;
            this.answers.scrollTop = 1;
        }
    }

    public meetingMessage: string|null = null;
    private connectionInterval: number|undefined;
    private quorum = false;

    private updatePrivateOnly() {
        if (this.context?.meta?.disableSharedManipulation) {
            return;
        }
        this.lightbox.getViewers().forEach(viewer => {
            const img = viewer.getRenderer()?.img;
            if (this.quorum && ((img?.distribution === 'all') || ((img?.distribution === 'restricted') && (this.resourceStatus.get(img.id) ?? false)))) {
                viewer.setPrivateOnly(false);
                if (this.context?.component === ComponentDetails.ROLE_CANDIDATE) {
                    viewer.setShareMode(SHARE_MODE.BROADCAST);
                } else {
                    viewer.setShareMode(SHARE_MODE.RECEIVE);
                }
            } else {
                viewer.setPrivateOnly(true);
                viewer.setShareMode(SHARE_MODE.PRIVATE);
            }
        });
    }

    private async updateStatus(roles: Map<string, number>): Promise<void> {
        if (!this.context) {
            throw new DOMException('context not defined', 'InvalidStateError');
        }
        console.warn('ROLES', roles, this.isRosce, this.isCandidate, roles.get('examiner'));
        if (!this.context.meta?.disableResourceLocking) {
            if (this.isRosce && this.isCandidate) {
                this.setResourceLocked((roles.get('examiner') ?? 0) < 1);
            }
        }
        if (this.isRosce) {
            switch(this.context.component) {
                case ComponentDetails.ROLE_CANDIDATE:
                    if ((roles.get('examiner') ?? 0) > 0) {
                        if (!this.quorum) {
                            this.quorum = true;
                            this.updatePrivateOnly();
                        }
                        this.context.meeting.requestStatus();
                        if (this.context.timing.getTimers().start('connected')) {
                            await this.checkpointQuestion();
                        }
                    } else {
                        if (this.quorum) {
                            this.quorum = false;
                            this.updatePrivateOnly();
                        }
                        this.setResourceStatus();
                        if (this.context.timing.getTimers().stop('connected')) {
                            await this.checkpointQuestion();
                        }
                    }
                    break;
                case ComponentDetails.ROLE_EXAMINER:
                    if ((roles.get('candidate') ?? 0) > 0) {
                        if (!this.quorum) {
                            this.quorum = true;
                            this.updatePrivateOnly();
                        }
                        this.disableShowHide(false);
                        if (this.context.timing.getTimers().start('connected')) {
                            await this.checkpointQuestion();
                        }
                    } else {
                        if (this.quorum) {
                            this.quorum = false;
                            this.updatePrivateOnly();
                        }
                        this.disableShowHide(true);
                        if (this.context.timing.getTimers().stop('connected')) {
                            await this.checkpointQuestion();
                        }
                    }
                    break;
                default:
                    if (((roles.get('candidate') ?? 0) > 0) && ((roles.get('examiner') ?? 0) > 0)) {
                        if (!this.quorum) {
                            this.quorum = true;
                            this.updatePrivateOnly();
                        }
                        this.context.meeting.requestStatus();
                        if (this.context.timing.getTimers().start('connected')) {
                            await this.checkpointQuestion();
                        }
                    } else {
                        if (this.quorum) {
                            this.quorum = false;
                            this.updatePrivateOnly();
                        }
                        this.setResourceStatus();
                        if (this.context.timing.getTimers().stop('connected')) {
                            await this.checkpointQuestion();
                        }
                    }
                    break;
            }
        }
    }

    public async handleMeetingEvent(event: MeetingEvent): Promise<void> {
        if (!this.context) {
            throw new DOMException('context not defined', 'InvalidStateError');
        }
        switch (event.type) {
        case 'connectedRoles':
            this.context.meeting.sendTime({
                type: 'connectionTime',
                round: this.round ?? -1,
                connectionTime: this.context.timing.getTimers().get('connected')
            });
            if (event.roles instanceof Map) {
                await this.updateStatus(event.roles);
            }
            break;
        case 'message':
            if (event.html) {
                this.meetingMessage = event.html;
                this.context.notifications.warning(this.meetingMessage);
            } else {
                this.meetingMessage = null;
                this.context.notifications.none();
            }
            break;
        case 'connectionTime':
            if (event.round !== this.round) {
                console.warn('CONNECTION TIME FROM PREVIOUD ROUND', event);
            } else {
                console.debug('CONNECTION TIME RECEIVED', event);
                const timers = this.context.timing.getTimers();
                timers.set('connected', Math.max(event.connectionTime, timers.get('connected')));
            }
            break;
        }
    }

    private resourceStatus = new Map<string, boolean>();

    public handleSendStatus(status: {id: string, released: boolean}): void {
        if (!this.context) {
            throw new DOMException('context not defined', 'InvalidStateError');
        }
        this.context.meeting.sendStatus([status]);
        this.resourceStatus.set(status.id, status.released);
        this.updatePrivateOnly();
    }

    public getResourceStatus(status: {id: string, released: boolean}[]): {id: string, released: boolean}[] {
        Array.from(this.resourceStatus.entries()).map(([id, released]) => {
            status.push({id, released})
        });
        return status;
    }

    private setResourceStatusOnly(status: {id?: string, released: boolean}): void {
        this.thumbnails?.setStatus(status);
        for (const component of this.components) {
            component.thumbnails?.setStatus(status);
        }
    }

    public setResourceStatus(status?: {id: string, released: boolean}): void {
        if (status === undefined) {
            this.setResourceStatusOnly({released: false});
            this.resourceStatus.clear();
        } else {
            this.setResourceStatusOnly(status);
            this.resourceStatus.set(status.id, status.released);
        }
    }

    public applyCommand(message: unknown): void {
        if (isImageCommand(message)) {
            this.lightbox.applyCommand(message);
        }
    }

    public commandObservation(cmd: ImageCommand) {
        if (this.context?.meeting) {
            this.context.meeting.sendCommandMessage(cmd);
        }
    }

    private isNavigationLocked = false;
    private isResourceLocked = false;
    private isReadOnly = false;
    private isPaused = false;

    private updateLocks(isNavigating = this.context?.navigation.getNavigating() ?? false) {
        const disableResourceViewing = this.isResourceLocked || this.isPaused;
        const disableResourceOpen = disableResourceViewing || isNavigating || this.isPaused;
        const disableWrite = this.isReadOnly || isNavigating || this.isPaused;
        this.lightbox.disabled(disableResourceViewing);
        this.thumbnails?.disabled(disableResourceOpen);
        for (const component of this.components) {
            component.disableResources(disableResourceOpen);
            component.setReadOnly(disableWrite);
        }
    }

    public getReadOnly(): boolean {
        return this.isReadOnly;
    }

    public setReadOnly(isReadOnly: boolean): void {
        this.isReadOnly = isReadOnly;
        for (const component of this.components) {
            component.setReadOnly(isReadOnly);
        }
        this.context?.navigation.setNavigating();
    }

    public setNavigating(isNavigating: boolean): void {
        this.updateLocks(isNavigating);
    }

    public getNavigationLocked(): boolean {
        return this.isNavigationLocked;
    }

    public setNavigationLocked(isLocked: boolean): void {
        if (isLocked != this.isNavigationLocked) {
            this.isNavigationLocked = isLocked;
            this.context?.navigation.setNavigating();
        }
    }

    public setPaused(isPaused: boolean): void {
        if (isPaused != this.isPaused) {
            this.isPaused = isPaused;
            this.context?.navigation.setNavigating();
        }
    }

    public getResourceLocked(): boolean {
        return this.isResourceLocked;
    }

    public setResourceLocked(isResourceLocked: boolean): void {
        isResourceLocked &&= !this.context?.meta?.disableResourceLocking && this.isCandidate;
        this.isResourceLocked = isResourceLocked;
        this.updateLocks();
    }

    public disableShowHide(disable: boolean): void {
        this.thumbnails?.disableShowHide(disable);
        for (const component of this.components) {
            component.thumbnails?.disableShowHide(disable);
        }
    }

    public async destroy(): Promise<void> {
        this.answers.removeEventListener('scroll', this.handleScroll);
        this.question.removeEventListener('scroll', this.handleScroll);
        this.question.removeEventListener('visibility', this.handleVisibility);

        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }

        removeNode(this.page);

        if (this.thumbnails) {
            this.thumbnails.destroy();
            this.thumbnails = undefined;
        }

        await this.lightbox.destroy();

        for (const component of this.components) {
            component.destroy();
        }
        this.components = [];
        this.componentMap.clear();
    }

    private async loadFlags(): Promise<void> {
        for (const component of this.components) {
            await component.loadFlag();
        }
    }

    private async loadAnswers(): Promise<void> {
        for (const component of this.components) {
            const response = await this.context?.responses.loadAnswer(component.qno, component.ano);
            component.loadAnswer(response);
        }
    }

    public setFocus(n: number): void {
        this.components[n]?.focus();
    }
}

window.customElements.define('question-viewer', QuestionViewerElement);

declare global {
    interface HTMLElementTagNameMap {
     'question-viewer': QuestionViewerElement;
   }
}
