import {faExpand, faCompress, faTimes, faArrowLeft, faArrowRight, faUndo, faEye, faEyeSlash, faBroadcastTower} from '@fortawesome/free-solid-svg-icons';
import {
    Transform, estimateTranslationScaling,
    estimateTranslationScalingRotation, detectBrowser,
    removeNode, makeElements, wait, NodeSpecObject, replaceIcon,
    /*getReqFullscreen, getExitFullscreen,*/ removeChildren, mkNode, scrollRangeIntoView, roundTo, isIndexed, isTransform
} from '@p4b/utils';
import { Renderer } from '@p4b/image-base';
import { isDicomRenderer } from '@p4b/Renderers/render-dicom';
import ResizeObserver from 'resize-observer-polyfill';
import { PdfRenderer } from '@p4b/Renderers/render-pdf';
import { translate } from '@p4b/utils-lang';
import { CanvasRenderingContext2D } from 'canvas';
import { configUserToolPanel, configUserToolTab } from './exam-accessibility';

export { makeRenderer } from '@p4b/image-base';

//----------------------------------------------------------------------------
// Viewer Commands

type Point = {
    x: number,
    y: number,
}

export function isPoint(p: unknown): p is Point {
    return isIndexed(p) && typeof p.x === 'number' && typeof p.y === 'number';
}

export function isPointList(ps: unknown): ps is Point[] {
    return Array.isArray(ps) && ps.every(p => Array.isArray(p) && p.length === 2 && typeof p[0] === 'number' && typeof p[1] === 'number');
}

const MODES = [
    'pan',
    'zoom',
    'rotate',
    'scroll',
    'window',
    'abdomen',
    'pulmonary',
    'brain',
    'bone',
    'point',
    'poly',
    'measure',
    'ellipse',
    'rectangle',
    'reset',
    'close',
    'notes',
] as const;

type Modes = typeof MODES[number];

function isMode(x: unknown): x is Modes {
    return typeof x === 'string' && MODES.includes(x as Modes);
}

type MeasurePoint = {type: 'point', p?: Point};
type MeasureLine = {type: 'line', p0: Point, p1: Point, showSize: boolean};
type MeasureRect = {type: 'rect', p0: Point, p1: Point};
type MeasureEllipse = {type: 'ellipse', p0: Point, p1: Point};
type MeasurePoly = {type: 'poly', ps: [number, number][], selected: number};
type MeasureType = MeasurePoint | MeasureLine | MeasureRect | MeasureEllipse | MeasurePoly;

export function isMeasureType(x: unknown): x is MeasureType {
    return isIndexed(x) && (
        (x.type === 'point' && (isPoint(x.p) || typeof x.p === 'undefined')) ||
        (x.type === 'line' && isPoint(x.p0) && isPoint(x.p1) && typeof x.showSize === 'boolean') ||
        (x.type === 'rect' && isPoint(x.p0) && isPoint(x.p1)) ||
        (x.type === 'ellipse' && isPoint(x.p0) && isPoint(x.p1)) ||
        (x.type === 'poly' && isPointList(x.ps))
    );
}

export function isMeasureList(ms: unknown): ms is MeasureType[] {
    return Array.isArray(ms) && ms.every(m => isMeasureType(m));
}

function mkPoint(p?: Point): MeasurePoint {
    return {type: 'point', p};
}

function mkLine(p0 = {x: 0, y: 0}, p1 = {x: 0, y: 0}, showSize = true): MeasureLine {
    return {type: 'line', p0, p1, showSize};
}

function mkRect(p0 = {x: 0, y: 0}, p1 = {x: 0, y: 0}): MeasureRect {
    return {type: 'rect', p0, p1};
}

function mkEllipse(p0 = {x: 0, y: 0}, p1 = {x: 0, y: 0}): MeasureEllipse {
    return {type: 'ellipse', p0, p1};
}

function mkPoly(ps: [number, number][]): MeasureType {
    return {type: 'poly', ps, selected: -1};
}

type GetState = {
    type: 'get',
    id?: string,
}

type SetState = {
    type: 'set',
    id?: string,
    cursor?: Point,
    mode?: Modes,
    slice?: number,
    centre?: number,
    width?: number,
    transform?: Transform,
    measures?: MeasureType[]|null,
}

export type ImageCommand = GetState | SetState;

export interface ImageCmdObserver {
    commandObservation: (command: ImageCommand) => void;
}

export function isImageCommand(x: unknown): x is ImageCommand {
    return isIndexed(x) && typeof x.type === 'string' && ((
        x.type === 'set' && typeof x.id === 'string' &&
        (x.cursor === undefined || isPoint(x.cursor)) &&
        (x.mode === undefined || isMode(x.mode)) &&
        (x.transform === undefined || isTransform(x.transform)) &&
        (x.measures == undefined || isMeasureList(x.measures)) &&
        (x.slice === undefined || typeof x.slice === 'number') &&
        (x.centre === undefined || typeof x.centre === 'number') &&
        (x.width === undefined || typeof x.width === 'number')
    ) || x.type === 'get');
}

export enum SHARE_MODE {
    PRIVATE,
    BROADCAST,
    RECEIVE,
}

//----------------------------------------------------------------------------
// JSON DOM Component

const measureColour = 'red';
const overlayColour = '#0f72c3';
const cursorColour = 'blue';

function drawCursor(context: CanvasRenderingContext2D, pixelScale: number, p: Point) {
    context.setTransform(1, 0, 0, 1, 0, 0);
        context.lineWidth = pixelScale;
        context.strokeStyle = 'black';
        context.fillStyle = cursorColour;
        context.lineJoin = 'miter';
        context.miterLimit = 2;
        context.font = `${16 * pixelScale}px "Noto Sans"`;
        context.beginPath();
        context.moveTo(p.x, p.y);
        context.lineTo(p.x + 16 * pixelScale, p.y + 8 * pixelScale);
        context.lineTo(p.x + 8 * pixelScale, p.y + 16 * pixelScale);
        context.closePath();
        context.stroke();
        context.fill('evenodd');
}

function xorder({x: x0, y: y0}: {x: number, y: number}, {x: x1,y: y1}: {x: number, y: number}): [{x: number, y: number}, {x: number, y: number}] {
    if (x0 <= x1) {
        return [{x: x0, y: y0}, {x: x1, y: y1}];
    } else {
        return [{x: x1, y: y1}, {x: x0, y: y0}];
    }
}

function unitName(units: string): {units: string, scale: (x: number) => number} {
    switch (units.toUpperCase()) {
        case 'OD':
            return {units: 'OD', scale: x => x / 1000};
        case 'HU':
            return {units: 'HU', scale: x => x};
        case 'US':
            return {units: '', scale: x => x};
        case 'MGML':
            return {units: 'mg/ml', scale: x => x};
        case 'Z_EFF':
            return {units: 'Eff-Z', scale: x => x};
        case 'ED':
            return {units: 'e/ml', scale: x => x * 1023};
        case 'EDW':
            return {units: 'EDNW', scale: x => x};
        case 'HU_MOD':
            return {units: 'HU(mod)', scale: x => x};
        case 'PCT':
            return {units: '%', scale: x => x};
        default:
            if (units.match(/Houndsfield Unit/ig)) {
                return {units: 'HU', scale: x => x};
            }
            return {units, scale: x => x};
    }
}

type DrawContext = {
    context: CanvasRenderingContext2D,
    transform: Transform,
    pixelScale: number,
    renderer: Renderer,
    sx?: number,
    sy?: number,
    handleRadius?: number,
}

type SelectContext = {
    x: number,
    y: number,
    t: Transform,
    pixelScale: number,
    handleRadius?: number,
}

type Measurable<T> = {
    draw(m: T, d: DrawContext): void;
    select(m: T, s: SelectContext): T[];
    origin(m: T, p: Point): void;
    point(m: T, p: Point): void;
    size(m: T): number;
    coords(m: T): [number, number][];
}

const pointMeasurable: Measurable<MeasurePoint> = {
    draw(m, {context, transform, pixelScale, handleRadius = 8}) {
        const p = (m as MeasurePoint).p;
        if (p) {
            const {x, y} = transform.apply(p)
            , r = handleRadius * pixelScale
            , w = 2 * pixelScale
            ;
            context.setTransform(1, 0, 0, 1, 0, 0);
            context.lineWidth = pixelScale;
            context.strokeStyle = 'black';
            context.fillStyle = measureColour;
            context.lineJoin = 'miter';
            context.miterLimit = 2;
            context.font = `${16 * pixelScale}px "Noto Sans"`;
            context.beginPath();
            context.moveTo(x + r, y);
            context.ellipse(x, y, r, r, 0, 0, 2*Math.PI);
            context.moveTo(x + (r + w), y);
            context.ellipse(x, y, r + w, r + w, 0, 0, 2*Math.PI);
            context.moveTo(x + r, y);
            context.stroke();
            context.fill('evenodd');
        }
    },
    select(m) {return [m]},
    origin(m, p) {m.p = p},
    point(m, p) {m.p = p},
    size() {return 1},
    coords(m) {return m.p ? [[m.p.x, m.p.y]]: []}
}

const lineMeasurable: Measurable<MeasureLine> = {
    draw(m, {context, transform, pixelScale, sx, sy, handleRadius = 8}) {
        const [{x: x0, y: y0}, {x: x1, y: y1}] = xorder(transform.apply(m.p0), transform.apply(m.p1))
        , r = handleRadius * pixelScale
        , w = 2 * pixelScale
        , sx2 = sx && sx * sx
        , sy2 = sy && sy * sy
        ;
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.lineWidth = pixelScale;
        context.strokeStyle = 'black';
        context.fillStyle = measureColour;
        context.lineJoin = 'miter';
        context.miterLimit = 2;
        context.font = `${16 * pixelScale}px "Noto Sans"`;
        context.beginPath();
        line({context, x0, y0, x1, y1, r, w});
        context.stroke();
        context.fill('evenodd');
        if (m.showSize) {
            let tx = 1, ty = 1, unit = 'px';
            if (sx2 !== undefined && sy2 !== undefined) {
                tx *= sx2;
                ty *= sy2;
                unit = 'mm';
            }
            const du = m.p1.x - m.p0.x
            , dv = m.p1.y - m.p0.y
            , measure = Math.round(Math.sqrt(du * du * tx + dv * dv * ty) * 100) / 100
            , text = `${measure}${unit}`
            ;
            context.textBaseline = 'middle';
            context.strokeText(text, x1 + r + 3 * w, y1);
            context.fillText(text, x1 + r + 3 * w, y1);
        }
    },
    select(m, {x, y, t, pixelScale, handleRadius = 8}) {
        const {x: x1, y: y1} = t.apply(m.p1)
        , dx = x - x1
        , dy = y - y1
        ;
        if (Math.sqrt(dx * dx + dy * dy) < handleRadius * pixelScale) {
            return [m];
        }
        const {x: x0, y: y0} = t.apply(m.p0)
        , du = x - x0
        , dv = y - y0
        ;
        if (Math.sqrt(du * du + dv * dv) < handleRadius * pixelScale) {
            [m.p0, m.p1] = [m.p1, m.p0];
            return [m];
        }
        return [];
    },
    origin(m, p) {m.p0 = p},
    point(m, p) {m.p1 = p},
    size(m) {
        const dx = m.p1.x - m.p0.x;
        const dy = m.p1.y - m.p0.y;
        return Math.sqrt(dx * dx + dy * dy);
    },
    coords(m) {return [[m.p0.x, m.p0.y], [m.p1.x, m.p1.y]]},
}

const rectMeasurable: Measurable<MeasureRect> = {
    draw(m, {context, transform, pixelScale, renderer, sx, sy, handleRadius = 8}) {
        let {x: x0, y: y0} = m.p0
        , {x: x1, y: y1} = m.p1
        ;

        if (x1 < x0) {
            [x0, x1] = [x1, x0];
            [y0, y1] = [y1, y0];
        }

        const xr = handleRadius * pixelScale / transform.scaling()
        , yr = (y1 < y0) ? -xr : xr
        , w = 2 * pixelScale
        , lh = 16 * pixelScale
        , ps = [
            transform.apply({x: x0, y: y0 - yr}),
            transform.apply({x: x0, y: y1}),
            transform.apply({x: x1 + xr, y: y1}),
            transform.apply({x: x0 - xr, y: y0}),
            transform.apply({x: x1, y: y0}),
            transform.apply({x: x1, y: y1 + yr}),
        ];

        context.setTransform(1, 0, 0, 1, 0, 0);
        context.lineJoin = 'miter';
        context.miterLimit = 2;
        context.font = `${lh}px "Noto Sans"`;

        context.beginPath();
        context.strokeStyle = 'black';
        context.lineWidth = 3 * pixelScale;
        context.moveTo(ps[0].x, ps[0].y);
        context.lineTo(ps[1].x, ps[1].y);
        context.lineTo(ps[2].x, ps[2].y);
        context.moveTo(ps[3].x, ps[3].y);
        context.lineTo(ps[4].x, ps[4].y);
        context.lineTo(ps[5].x, ps[5].y);
        context.stroke();

        context.beginPath();
        context.strokeStyle = measureColour;
        context.lineWidth = 2 * pixelScale;
        context.moveTo(ps[0].x, ps[0].y);
        context.lineTo(ps[1].x, ps[1].y);
        context.lineTo(ps[2].x, ps[2].y);
        context.moveTo(ps[3].x, ps[3].y);
        context.lineTo(ps[4].x, ps[4].y);
        context.lineTo(ps[5].x, ps[5].y);
        context.stroke();

        let vx = ps[0].x;
        let vy = ps[0].y;
        for (let i = 1; i < ps.length; ++i) {
            if (ps[i].x > vx) {
                vx = ps[i].x;
                vy = ps[i].y;
            }
        }

        context.strokeStyle = 'black';
        context.fillStyle = measureColour;
        context.lineWidth = pixelScale;
        let tx = 1, ty = 1, unit = 'px\u00b2';
        if (sx !== undefined && sy !== undefined) {
            tx *= sx;
            ty *= sy;
            unit = 'mm\u00b2';
        }
        const du = Math.abs(m.p1.x - m.p0.x)
        , dv = Math.abs(m.p1.y - m.p0.y)
        , measure = Math.round(du * tx * dv * ty * 100) / 100
        , text = `${measure} ${unit}`
        ;
        context.textBaseline = 'middle';
        context.strokeText(text, vx + 3 * w, vy);
        context.fillText(text, vx + 3 * w, vy);

        if (isDicomRenderer(renderer)) {
            const {mean, stddev} = renderer.convexMean({y0: m.p0.y, y1: m.p1.y, f: () => ({x0: m.p0.x, x1: m.p1.x})})
            , {units, scale} = unitName(renderer.img.rescaleType ?? ((renderer.img.modality?.toUpperCase() === 'CT') ? 'HU' : ''))
            ;
            let dy = 1.5 * lh;
            if (mean !== undefined && mean === mean) {
                const mtxt = `\u03bc = ${scale(roundTo(mean, 2))}${units ? ` ${units}` : ''}`;
                context.strokeText(mtxt, vx + 3 * w, vy - dy);
                context.fillText(mtxt, vx + 3 * w, vy - dy);
                dy += 1.5 * lh;
            }
            if (stddev !== undefined && stddev === stddev) {
                const stxt = `\u03c3 = ${scale(roundTo(stddev, 2))}${units ? ` ${units}` : ''}`;
                context.strokeText(stxt, vx + 3 * w, vy - dy);
                context.fillText(stxt, vx + 3 * w, vy - dy);
            }
        }
    },
    select(m, {x, y, t, pixelScale, handleRadius = 8}) {
        const {x: x1, y: y1} = t.apply(m.p1)
        , dx = x - x1
        , dy = y - y1
        ;
        if (Math.sqrt(dx * dx + dy * dy) < handleRadius * pixelScale) {
            return [m];
        }
        const {x: x0, y: y0} = t.apply(m.p0)
        , du = x - x0
        , dv = y - y0
        ;
        if (Math.sqrt(du * du + dv * dv) < handleRadius * pixelScale) {
            [m.p0, m.p1] = [m.p1, m.p0];
            return [m];
        }
        return [];
    },
    origin(m, p) {m.p0 = p},
    point(m, p) {m.p1 = p},
    size(m) {
        const dx = m.p1.x - m.p0.x;
        const dy = m.p1.y - m.p0.y;
        return Math.abs(dx * dy);
    },
    coords(m) {return [[m.p0.x, m.p0.y], [m.p1.x, m.p1.y]]},
}

const ellipseMeasurable: Measurable<MeasureEllipse> = {
    draw(m, {context, transform, pixelScale, renderer, sx, sy, handleRadius = 8}) {
        const [{x: x0, y: y0}, {x: x1, y: y1}] = xorder(transform.apply(m.p0), transform.apply(m.p1))
        , r = handleRadius * pixelScale
        , w = 2 * pixelScale
        , lh = 16 * pixelScale
        , dx = x1 - x0
        , dy = y1 - y0
        , mx = Math.abs(m.p1.x - m.p0.x) * 0.5 * transform.scaling()
        , my = Math.abs(m.p1.y - m.p0.y) * 0.5 * transform.scaling()
        ;
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.lineWidth = 1 * pixelScale;
        context.strokeStyle = 'black';
        context.fillStyle = measureColour;
        context.lineJoin = 'miter';
        context.miterLimit = 2;
        context.font = `${lh}px "Noto Sans"`;

        handle({context, x: x0, y: y0, width: w, size: r});
        if (dy > 0 || dx > 0) {
            handle({context, x: x1, y: y1, width: w, size: r});
        }

        const a = transform.rotation();
        const {x: ax, y: ay} = transform.apply({x: Math.max(m.p0.x, m.p1.x), y: (m.p0.y + m.p1.y) / 2});
        context.beginPath();
        context.moveTo(ax, ay);
        context.ellipse((x0 + x1) / 2, (y0 + y1) / 2, mx, my, a, 0, 2*Math.PI);
        context.ellipse((x0 + x1) / 2, (y0 + y1) / 2, mx + w, my + w, a, 0, 2*Math.PI);
        context.stroke();
        context.fill('evenodd');

        const vx = x1 + r;
        const vy = y1;

        let tx = 1, ty = 1, unit = 'px\u00b2';
        if (sx !== undefined && sy !== undefined) {
            tx *= sx;
            ty *= sy;
            unit = 'mm\u00b2';
        }
        const du = Math.abs((m.p1.x - m.p0.x) / 2)
        , dv = Math.abs((m.p1.y - m.p0.y) / 2)
        , measure = Math.round(Math.PI * du * tx * dv * ty * 100) / 100
        , text = `${measure}${unit}`
        ;
        context.textBaseline = 'middle';
        context.strokeText(text, vx + 3 * w, vy);
        context.fillText(text, vx + 3 * w, vy);

        if (isDicomRenderer(renderer)) {
            const mx = (m.p0.x + m.p1.x) / 2.0
            , my =(m.p0.y + m.p1.y) / 2.0
            , a = (m.p1.x - m.p0.x) / 2.0
            , b = (m.p1.y - m.p0.y) / 2.0
            , a2 = a * a
            , ab = a2 / (b * b)
            , {mean, stddev} = renderer.convexMean({y0: m.p0.y, y1: m.p1.y, f: y => {
                const dx = Math.sqrt(a2 - ab * (y - my) ** 2);
                return {x0: mx - dx , x1: mx + dx};
              }})
            , {units, scale} = unitName(renderer.img.rescaleType ?? ((renderer.img.modality?.toUpperCase() === 'CT') ? 'HU' : ''))
            ;
            let dy = 1.5 * lh;
            if (mean !== undefined && mean === mean) {
                const mtxt = `\u03bc = ${roundTo(scale(mean), 2)}${units ? ` ${units}` : ''}`;
                context.strokeText(mtxt, vx + 3 * w, vy - dy);
                context.fillText(mtxt, vx + 3 * w, vy - dy);
                dy += 1.5 * lh;
            }
            if (stddev !== undefined && stddev === stddev) {
                const stxt = `\u03c3 = ${roundTo(scale(stddev), 2)}${units ? ` ${units}` : ''}`;
                context.strokeText(stxt, vx + 3 * w, vy - dy);
                context.fillText(stxt, vx + 3 * w, vy - dy);
            }
        }
    },
    select(m, {x, y, t, pixelScale, handleRadius = 8}) {
        const {x: x1, y: y1} = t.apply(m.p1)
        , dx = x - x1
        , dy = y - y1
        ;
        if (Math.sqrt(dx * dx + dy * dy) < handleRadius * pixelScale) {
            return [m];
        }
        const {x: x0, y: y0} = t.apply(m.p0)
        , du = x - x0
        , dv = y - y0
        ;
        if (Math.sqrt(du * du + dv * dv) < handleRadius * pixelScale) {
            [m.p0, m.p1] = [m.p1, m.p0];
            return [m];
        }
        return [];
    },
    origin(m, p) {m.p0 = p},
    point(m, p) {m.p1 = p},

    size(m) {
        const dx = m.p1.x - m.p0.x;
        const dy = m.p1.y - m.p0.y;
        return Math.sqrt(dx * dx + dy * dy);
    },
    coords(m) {return [[m.p0.x, m.p0.y], [m.p1.x, m.p1.y]]},
}

const polyMeasurable: Measurable<MeasurePoly> = {
    draw(m, {context, transform, pixelScale, handleRadius = 8}) {
        if (m.ps.length > 0) {
            const r = handleRadius * pixelScale
            , w = 2 * pixelScale
            ;
            context.setTransform(1, 0, 0, 1, 0, 0);
            context.lineWidth = pixelScale;
            context.strokeStyle = 'black';
            context.fillStyle = 'yellow';
            context.lineJoin = 'miter';
            context.miterLimit = 2;
            context.font = `${16 * pixelScale}px "Noto Sans"`;
            context.beginPath();
            if (m.ps.length < 3) {
                const {x: x0, y: y0} = transform.apply({x: m.ps[0][0], y: m.ps[0][1]});
                if (m.ps.length < 2) {
                    const x1 = x0
                    , y1 = y0
                    ;
                    line({context, x0, y0, x1, y1, r, w});
                } else {
                    const {x: x1, y: y1} = transform.apply({x: m.ps[1][0], y: m.ps[1][1]});
                    line({context, x0, y0, x1, y1, r, w});
                }
            } else {
                const n = m.ps.length;
                for(let i = 0; i < n; ++i) {
                    const {x:u0, y:v0} = transform.apply({x: m.ps[i][0], y: m.ps[i][1]})
                    ;
                    context.moveTo(u0 + r, v0);
                    context.ellipse(u0, v0, r, r, 0, 0, 2*Math.PI);
                }
                for(let i = 0; i < n; ++i) {
                    const i1 = (i + 1) % n
                    , i2 = (i + n - 1) % n
                    , {x:u0, y:v0} = transform.apply({x: m.ps[i][0], y: m.ps[i][1]})
                    , {x: u1, y: v1} = transform.apply({x: m.ps[i1][0], y: m.ps[i1][1]})
                    , {x: u2, y: v2} = transform.apply({x: m.ps[i2][0], y: m.ps[i2][1]})
                    , du = u1 - u0
                    , dv = v1 - v0
                    , dx = u2 - u0
                    , dy = v2 - v0
                    , a1 = Math.atan2(dv, du)
                    , a2 = Math.atan2(dy, dx)
                    , b = Math.atan2(w / 2, r + w)
                    ;
                    if (i === 0) {
                        context.moveTo(u0 + (r + w) * Math.cos(a2 + b), v0 + (r + w) * Math.sin(a2 + b));
                    }
                    context.ellipse(u0, v0, r + w, r + w, 0, a2 + b, a1 - b);
                    if (i === n - 1) {
                        context.lineTo(u1 - (r + w) * Math.cos(a1 + b), v1 - (r + w) * Math.sin(a1 + b));
                    }
                }
                for(let i = n - 1; i >= 0; --i) {
                    const i1 = (i + 1) % n
                    , i2 = (i + n - 1) % n
                    , {x:u0, y:v0} = transform.apply({x: m.ps[i][0], y: m.ps[i][1]})
                    , {x: u1, y: v1} = transform.apply({x: m.ps[i1][0], y: m.ps[i1][1]})
                    , {x: u2, y: v2} = transform.apply({x: m.ps[i2][0], y: m.ps[i2][1]})
                    , du = u1 - u0
                    , dv = v1 - v0
                    , dx = u2 - u0
                    , dy = v2 - v0
                    , a1 = Math.atan2(dv, du)
                    , a2 = Math.atan2(dy, dx)
                    , b = Math.atan2(w / 2, r + w)
                    ;
                    if (i === n - 1) {
                        context.moveTo(u0 + (r + w) * Math.cos(a1 + b), v0 + (r + w) * Math.sin(a1 + b));
                    }
                    context.ellipse(u0, v0, r + w, r + w, 0, a1 + b, a2 - b);
                    if (i === 0) {
                        context.lineTo(u2 - (r + w) * Math.cos(a2 + b), v2 - (r + w) * Math.sin(a2 + b));
                    }
                }
            }
            context.stroke();
            context.fill('evenodd');
        }
    },
    select(m, {x, y, t, pixelScale, handleRadius = 8}) {
        for (let i = 0; i < m.ps.length; ++i) {
            const [u, v] = m.ps[i]
            , {x: p, y: q} = t.apply({x: u, y: v})
            , dx = x - p
            , dy = y - q
            ;
            if (Math.sqrt(dx * dx + dy * dy) < handleRadius * pixelScale) {
                m.selected = i;
                return [m];
            }
        }
        m.selected = -1;
        return [];
    },
    origin(m, {x, y}) {
        if (m.ps.length < 3) {
            m.selected = m.ps.length;
            m.ps.push([x, y]);
        } else {
            const i = intersect(m.ps, [x, y]);
            if (i >= 0) {
                m.ps.splice(i + 1, 0, [x, y]);
                m.selected = i + 1;
            }
        }
    },
    point(m, {x, y}) {
        if (m.selected >= 0) {
            m.ps[m.selected] = [x, y];
        }
    },
    size(m) {return m.ps.length;},
    coords(m) {return m.ps},
}

function polyRemoveSelected(m: MeasurePoly): void {
    if (m.selected >= 0) {
        m.ps.splice(m.selected, 1);
        m.selected = -1;
    }
}

function measurableDraw(m: MeasureType, d: DrawContext): void {
    switch (m.type) {
        case 'point': return pointMeasurable.draw(m, d);
        case 'line': return lineMeasurable.draw(m, d);
        case 'rect': return rectMeasurable.draw(m, d);
        case 'ellipse': return ellipseMeasurable.draw(m, d);
        case 'poly': return polyMeasurable.draw(m, d);
    }
}

function measurableSelect(m: MeasureType, s: SelectContext): MeasureType[] {
    switch (m.type) {
        case 'point': return pointMeasurable.select(m, s);
        case 'line': return lineMeasurable.select(m, s);
        case 'rect': return rectMeasurable.select(m, s);
        case 'ellipse': return ellipseMeasurable.select(m, s);
        case 'poly': return polyMeasurable.select(m, s);
    }
}

function measurableOrigin(m: MeasureType, p: Point): void {
    switch (m.type) {
        case 'point': return pointMeasurable.origin(m, p);
        case 'line': return lineMeasurable.origin(m, p);
        case 'rect': return rectMeasurable.origin(m, p);
        case 'ellipse': return ellipseMeasurable.origin(m, p);
        case 'poly': return polyMeasurable.origin(m, p);
    }
}

function measurablePoint(m: MeasureType, p: Point): void {
    switch (m.type) {
        case 'point': return pointMeasurable.point(m, p);
        case 'line': return lineMeasurable.point(m, p);
        case 'rect': return rectMeasurable.point(m, p);
        case 'ellipse': return ellipseMeasurable.point(m, p);
        case 'poly': return polyMeasurable.point(m, p);
    }
}

function measurableSize(m: MeasureType): number {
    switch (m.type) {
        case 'point': return pointMeasurable.size(m);
        case 'line': return lineMeasurable.size(m);
        case 'rect': return rectMeasurable.size(m);
        case 'ellipse': return ellipseMeasurable.size(m);
        case 'poly': return polyMeasurable.size(m);
    }
}

function measurablePoints(m: MeasureType): [number, number][] {
    switch (m.type) {
        case 'point': return pointMeasurable.coords(m);
        case 'line': return lineMeasurable.coords(m);
        case 'rect': return rectMeasurable.coords(m);
        case 'ellipse': return ellipseMeasurable.coords(m);
        case 'poly': return polyMeasurable.coords(m);
    }
}

function line({context, x0, y0, x1, y1, r, w} : {context: CanvasRenderingContext2D, x0: number, y0: number, x1: number, y1: number, r: number, w: number}) {
    const dx = x1 - x0
    , dy = y1 - y0
    , length = Math.sqrt(dx*dx + dy*dy)
    , a = length > 0 ? Math.atan2(dy, dx) : 0
    ;
    if (length > 2 * (r + w)) {
        const b = Math.atan2(w / 2, r + w);
        context.moveTo(x0 + r, y0);
        context.ellipse(x0, y0, r, r, 0, 0, 2*Math.PI);
        context.moveTo(x1 + r, y1);
        context.ellipse(x1, y1, r, r, 0, 0, 2*Math.PI);
        context.moveTo(x0 + (r + w) * Math.cos(a + b), y0 + (r + w) * Math.sin(a + b));
        context.ellipse(x0, y0, r + w, r + w, 0, a + b, a + 2*Math.PI - b);
        context.ellipse(x1, y1, r + w, r + w, 0, a + b + Math.PI, a + 3*Math.PI - b);
        context.lineTo(x0 + (r + w) * Math.cos(a + b), y0 + (r + w) * Math.sin(a + b));
    } else {
        context.moveTo(x0 + r * Math.cos(a + Math.PI/2), y0 + r * Math.sin(a + Math.PI/2));
        context.ellipse(x0, y0, r, r, 0, a + 0.5*Math.PI, a + 1.5*Math.PI);
        context.ellipse(x1, y1, r, r, 0, a + 1.5*Math.PI, a + 0.5*Math.PI);
        context.lineTo(x0 + r * Math.cos(a + Math.PI/2), y0 + r * Math.sin(a + Math.PI/2));
        context.moveTo(x0 + (r + w) * Math.cos(a + Math.PI/2), y0 + (r + w) * Math.sin(a + Math.PI/2));
        context.ellipse(x0, y0, r + w, r + w, 0, a + 0.5*Math.PI, a + 1.5*Math.PI);
        context.ellipse(x1, y1, r + w, r + w, 0, a + 1.5*Math.PI, a + 0.5*Math.PI);
        context.lineTo(x0 + (r + w) * Math.cos(a + Math.PI/2), y0 + (r + w) * Math.sin(a + Math.PI/2));
    }
}

function avg(points: [number, number][]): [number, number] {
    let ax = 0, ay = 0;
    for (let i = 0; i < points.length; ++i) {
        const [x, y] = points[i];
        ax += x;
        ay += y;
    }
    return [ax / points.length, ay / points.length];
}

function area(points: [number, number][]): number {
    if (points.length > 2) {
        let a = 0, i = 1;
        while (i < points.length - 1) {
            a += points[i][0] * (points[i + 1][1] - points[i - 1][1]);
            ++i;
        }
        a += points[i][0] * (points[0][1] - points[i - 1][1]);
        a += points[0][0] * (points[1][1] - points[i][1]);
        return a / 2;
    } else {
        return 0;
    }
}

function centreOfMass(points: [number, number][]): [number, number] {
    let cx = 0, cy = 0, i = 0;
    if (points.length > 2) {
        while (i < points.length - 1) {
            const [x0, y0] = points[i]
            , [x1, y1] = points[(i + 1) % points.length]
            , d = (x0 * y1 - x1 * y0)
            ;
            cx += (x0 + x1) * d;
            cy += (y0 + y1) * d;
            ++i;
        }
        const [x0, y0] = points[i]
        , [x1, y1] = points[0]
        , d = (x0 * y1 - x1 * y0)
        , a6 = 6 * area(points)
        ;
        cx += (x0 + x1) * d;
        cy += (y0 + y1) * d;
        return [cx / a6, cy / a6];
    } else {
        return avg(points);
    }
}

function intersect(points: [number, number][], [rx, ry]: [number, number]): number {
    const [ax, ay] = centreOfMass(points);
    const bx = rx - ax;
    const by = ry - ay;
    for (let i = 0; i < points.length; ++i) {
        const [cx, cy] = points[i];
        const [ex, ey] = points[(i + 1) % points.length];
        const dx = ex - cx;
        const dy = ey - cy;
        const td = (bx * dy - by * dx);
        const ud = (dx * by - dy * bx);
        if (td !== 0 && ud !== 0) {
            const t = (dx * (ay - cy) + dy * (cx - ax)) / td;
            const u = (bx * (cy - ay) + by * (ax - cx)) / ud;
            if (0 < t && 0 < u && u <= 1) {
                return i;
            }
        }
    }
    return -1;
}

function handle({context, x, y, width, size}: {context: CanvasRenderingContext2D, x: number, y: number, width: number, size: number}) {
    const w = width / 2;
    context.beginPath();
    context.moveTo(x + w, y + w);
    context.lineTo(x + w + size, y + w);
    context.lineTo(x + w + size, y - w);
    context.lineTo(x + w, y - w);
    context.lineTo(x + w, y - w - size)
    context.lineTo(x - w, y - w - size);
    context.lineTo(x - w, y - w);
    context.lineTo(x - w - size, y - w);
    context.lineTo(x - w - size, y + w);
    context.lineTo(x - w, y + w);
    context.lineTo(x - w, y + w + size);
    context.lineTo(x + w, y + w + size);
    context.lineTo(x + w, y + w);
    context.stroke();
    context.fill('evenodd');
}


interface DicomViewerUi {
    viewer: HTMLDivElement;
    title: HTMLDivElement;
    canvasPanel: HTMLDivElement;
    canvas: HTMLCanvasElement;
    progress: HTMLSpanElement;
    control: HTMLSelectElement;
    broadcast: HTMLButtonElement;
    broadcastIcon: HTMLButtonElement;
    reset: HTMLButtonElement;
    prev: HTMLButtonElement;
    next: HTMLButtonElement;
    expand: HTMLButtonElement;
    expandIcon: HTMLSpanElement;
    close: HTMLButtonElement;
    tpar: HTMLDivElement;
    tspan: HTMLDivElement;
}

function getDicomViewerUi(): NodeSpecObject<DicomViewerUi> {
    return {
        viewer: { elem: 'div', className: 'viewer-panel config-user000-bg config-user000aaa-text', style: {display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%', height: '100%'}},
        title: { elem: 'div', className: 'title-new', parent: 'viewer'},
        canvasPanel: {elem: 'div', className: 'canvas-panel', style: {display: 'none', overflowY: 'auto'}, parent: 'viewer'},
        canvas: { elem: 'canvas', id: 'image-view', className: 'dicom-canvas', parent: 'canvasPanel', attrib: { tabindex: '0', 'moz-opaque': 'true' } },
        progress: { elem: 'span', className: 'canvas-progress', parent: 'canvasPanel' },
        tpar: {elem: 'div', className: `slim-control ${configUserToolPanel}`, parent: 'title', style: {
            flex: '1',
            padding: '4px 4px 4px 9px',
            marginLeft: '0',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        }},
        tspan: {elem: 'div', parent: 'tpar', style: {
            padding: '0',
            margin: '0',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        }},
        control: {
            elem: 'select', tip: translate('VIEWER_SELECT'), className: `slim-dropdown ${configUserToolTab}`, parent: 'title', attrib: {'data-test': 'btn-control'},
        },
        broadcast: {elem: 'button', tip: translate('VIEWER_BROADCAST'), className: `slim-control ${configUserToolTab}`, parent: 'title', attrib: {'data-test': 'btn-broadcast', 'hidden': 'true'}},
        broadcastIcon: {elem: 'span', parent: 'broadcast', children: [{elem: 'icon', className: 'control-icon'}]},
        reset: {elem: 'button', tip: translate('VIEWER_RESET'), className: `slim-control ${configUserToolTab}`, parent: 'title', attrib: {'data-test': 'btn-reset'}, children: [
            {elem: 'icon', className: 'control-icon', icon: faUndo},
        ]},
        prev: { elem: 'button', tip: translate('VIEWER_PREV'), className: `slim-control ${configUserToolTab}`, parent: 'title', attrib: {hidden: 'true', 'data-test': 'btn-prev'}, children: [
            {elem: 'icon', className: 'control-icon', icon: faArrowLeft},
        ]},
        next: { elem: 'button', tip: translate('VIEWER_NEXT'), className: `slim-control ${configUserToolTab}`, parent: 'title', attrib: {hidden: 'true', 'data-test': 'btn-next'}, children: [
            {elem: 'icon', className: 'control-icon', icon: faArrowRight},
        ]},
        expand: { elem: 'button', tip: translate('VIEWER_FULLSCREEN'), className: `slim-control ${configUserToolTab}`, parent: 'title', attrib: {'data-test': 'btn-expand'}},
        expandIcon: {elem: 'span', parent: 'expand', children: [{elem: 'icon', className: 'control-icon', icon: faExpand}]},
        close: { elem: 'button', tip: translate('VIEWER_CLOSE'), className: `slim-control ${configUserToolTab}`, parent: 'title', attrib: {'data-test': 'btn-close'}, children: [
            {elem: 'icon', className: 'control-icon', icon: faTimes},
        ]},
    }
}

function findOption(select: HTMLSelectElement, text: Modes): number|null {
    for (let i = 0; i < select.length; ++i) {
        if (select.options[i].value === text) {
            return i;
        }
    }
    return null;
}

function addOption(select: HTMLSelectElement, opt: Modes, nodeFn: (parent: HTMLSelectElement, value: string) => void): number {
    let i = findOption(select, opt);
    if (i === null) {
        i = select.options.length;
        nodeFn(select, opt);
    }
    return i;
}

//----------------------------------------------------------------------------
// DICOM Viewer

class Control {
    public scaleX = 1.0;
    public scaleY = 1.0;

    public readonly cssClass: string;
    public onstart?: (c: Control, x: number, y: number) => void;
    public onstep?: (x: number, y: number) => void;
    public onend?: () => void;
    public mode?: Modes;
    public shiftKey = false;

    public async start(point: Point, canvas: HTMLElement) {
        if (this.onstart != null) {
            const rect = canvas.getBoundingClientRect();
            this.onstart(this,
                (point.x - rect.left) * this.scaleX,
                (point.y - rect.top) * this.scaleY
            );
        }
    }

    public async step(point: Point, canvas: HTMLElement) {
        if (this.onstep != null) {
            const rect = canvas.getBoundingClientRect();
            this.onstep(
                (point.x - rect.left) * this.scaleX,
                (point.y - rect.top) * this.scaleY
            );
        }
    }

    public async end() {
        if (this.onend != null) {
            this.onend();
        }
    }

    public clear() {
        this.onstart = undefined;
        this.onstep = undefined;
        this.onend = undefined;
        this.mode = undefined;
    }

    public constructor(cssClass: string) {
        this.clear();
        this.cssClass = cssClass;
    }
}


class Location {
    public dx: number;
    public dy: number;
    public rx: number;
    public ry: number;

    public constructor(dx: number, dy: number, rx: number, ry: number) {
        this.dx = dx;
        this.dy = dy;
        this.rx = rx;
        this.ry = ry;
    }
}


interface DicomViewerArgs {
    //parent: HTMLElement;
    fullscreenParent?: Element; // HTMLElement;
    //scrollContainer: Element; // HTMLElement
    //after: Node|null; // HTMLElement | null;
    //sizeReference: Element; // HTMLElement;
    resources: {
        getImageBegin(): Promise<void>;
        getImageFrame(start: number, end: number): Promise<ArrayBuffer|undefined>;
        getImageEnd(): Promise<void>;
    };
    navigation: {
        getNavigating(): boolean;
    };
    //toggleFullscreen(fullscreen?: boolean): void;
    //noMouse: boolean;
    //window: Window;
    //forceFullscreen?: boolean;
    //compact?: boolean;
}


export class ImageViewerElement extends HTMLElement {
    private _args?: DicomViewerArgs = {
        resources: {
            getImageBegin: async () => undefined,
            getImageFrame: async () => undefined,
            getImageEnd: async () => undefined,
        },
        navigation: {
            getNavigating: () => false,
        }
    }
    private ui = makeElements(getDicomViewerUi());
    private renderer?: Renderer;
    private scaleTransform = Transform.identity;
    private left = new Control('control-selected-left');
    private right = new Control('control-selected-right');
    //private rem: number;
    //private renderRequested = false;
    private postFrame?: (context: CanvasRenderingContext2D, sx?: number, sy?: number, px?: number, py?: number) => void;
    private forceFullscreen = false;
    private fullscreen = false;
    private isHidden?: string;
    private visibilityChange?: string;
    private scaleX = 1.0;
    private scaleY = 1.0;
    private lastFrame: DOMHighResTimeStamp = 0;
    private resizeObserver: ResizeObserver;
    //private measures = new Map<number, Measure[]>();
    private measures = new  Map<number, MeasureType[]>();

    private compact = false;
    private cursor?: Point;

    public closeHook?: () => void;

    public getRenderer(): Renderer|undefined {
        return this.renderer;
    }

    public constructor() {
        super();
        console.debug('IMAGE-VIEWER CONSTRUCTED');
        this.resizeObserver = new ResizeObserver(this.handleResize);
        this.resizeObserver.observe(this.ui.canvasPanel);
    }

    public connectedCallback() {
        this.style.display = 'contents';

        console.debug('IMAGE-VIEWER CONNECTED');
        const fs = getComputedStyle(window.document.documentElement).fontSize;
        if (fs == null) {
            throw ('FONT_SIZE is null');
        }

        this.appendChild(this.ui.viewer);

        window.addEventListener('mousemove', this.windowMousemoveHandler, {passive: false});
        window.addEventListener('touchmove', this.windowTouchmoveHandler, {passive: false});
        window.addEventListener('mouseup', this.windowMouseupHandler, {passive: false});
        window.addEventListener('touchend', this.windowTouchendHandler, {passive: false});
        window.addEventListener('contextmenu', this.canvasContextmenuHandler, {passive: false});

        this.ui.canvas.addEventListener('keydown', this.canvasKeydownHandler, {passive: false});
        this.ui.canvas.addEventListener('click', this.canvasClickHandler, {passive: false});
        this.ui.canvas.addEventListener('dblclick', this.canvasDblclickHandler, {passive: false});
        this.ui.canvas.addEventListener('mousedown', this.canvasMousedownHandler, {passive: false});
        this.ui.canvas.addEventListener('touchstart', this.canvasTouchstartHandler, {passive: false});

        this.ui.control.addEventListener('change', this.controlChangeHandler, {passive: false});
        this.ui.broadcast.addEventListener('click', this.broadcastClickHandler, {passive: true});
        this.ui.close.addEventListener('click', this.closeClickHandler, {passive: true});
        this.ui.prev.addEventListener('click', this.prevClickHandler, {passive: true});
        this.ui.next.addEventListener('click', this.nextClickHandler, {passive: true});
        this.ui.expand.addEventListener('click', this.expandClickHandler, {passive: true});
        this.ui.reset.addEventListener('click', this.resetClickHandler, {passive: true});

        this.compact = this.getAttribute('compact') === 'true';
    }

    public disconnectedCallback() {
        console.debug('IMAGE-VIEWER DISCONNECTED');
        this.destroy();
    }

    public set args(args: DicomViewerArgs) {
        console.debug('IMAGE-VIEWER SET ARGS');
        this._args = args;
        this.ui.close.disabled = args.navigation.getNavigating();
    }

    //private unloadHandler = (): void => {
    //    this.destroy();
    //    //console.log('UNLOAD');
    //}

    /*private visibilityHandler = (): void => {
        if (this.isHidden) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            //console.log('VISIBILITY_CHANGE:', ((this.args.window.document as any)[this.hidden] as boolean));
        }
        //console.log(' GLOBAL:', window);
        //console.log('  LOCAL:', this.args.window)
    }*/

    //------------------------------------------------------------------------
    // Drawing Frames

    private async animationFrame(): Promise<void> {
        const context = this.ui.canvas.getContext('2d', { alpha: false }) as CanvasRenderingContext2D|null;
        if (context) {
            //context.resetTransform(); // not in iOS9
            context.setTransform(1, 0, 0, 1, 0, 0);
            //context.fillStyle='black';
            //context.fillRect(0, 0, this.ui.canvas.width, this.ui.canvas.height);
            if (this.renderer && this.scaleTransform) {
                //const t = this.transform.multiplyBy(this.scaleTransform);
                //const t = this.transform; // this.scaleTransform;
                const transform = this.scaleTransform.multiplyBy(this.transform);
                //context.setTransform(t.s, t.r, -t.r, t.s, t.tx, t.ty);
                await this.renderer.animationFrame(context, transform);
                const index = this.renderer?.index ?? 1;
                const measures = this.measures.get(index);
                if (measures) {
                    //context.setTransform(1, 0, 0, 1, 0, 0);
                    const args: {sx?: number, sy?: number} = {};
                    if (isDicomRenderer(this.renderer)) {
                        args.sx = this.renderer.img.pixelSpacing?.[0];
                        args.sy = this.renderer.img.pixelSpacing?.[1];
                    }
                    for (const measure of measures) {
                        measurableDraw(measure, {context, transform, pixelScale: this.scaleY, renderer: this.renderer, sx: args.sx, sy: args.sy});
                        //measure.draw(context, t, this.scaleY, this.renderer);
                    }
                }
                if (this.postFrame) {
                    context.setTransform(1, 0, 0, 1, 0, 0);
                    this.postFrame(context);
                }
                if (this.cursor) {
                    const p = this.scaleTransform.apply(this.cursor);
                    drawCursor(context, this.scaleY, p);
                }
            } else {
                //context.clearRect(0, 0, this.ui.canvas.width, this.ui.canvas.height);
                context.fillStyle='black';
                context.fillRect(0, 0, this.ui.canvas.width, this.ui.canvas.height);
                console.error('CANVAS or TRANSFORM error', this.renderer?.img, this.scaleTransform);
            }
        } else {
            console.error('CONTEXT error');
        }
    }

    private rendering = false;

    private async requestRender(): Promise<void> {
        if (!this.rendering) {
            this.rendering = true;
            try {
                if (this.renderer) {
                    await this.renderer.render();
                } else {
                    console.error('RENDERER is not defined');
                }
                this.requestFrame();
            } catch(err) {
                console.error('RENDER_ERROR', err);
            } finally {
                this.rendering = false;
            }
        }
    }

    private requestedAnimationFrame: number|undefined;

    private requestFrame(): void {
        if (this.requestedAnimationFrame !== undefined) {
            return;
        }
        this.requestedAnimationFrame = window.requestAnimationFrame((timestamp: DOMHighResTimeStamp) => (async () => {
            if (timestamp == this.lastFrame) {
                return;
            }
            this.lastFrame = timestamp;
            try {
                await this.animationFrame()
            } finally {
                this.requestedAnimationFrame = undefined;
            }
        })().catch(err => {
            console.error('FRAME ERROR', err);
        }));
    }

    /*
        console.log('REQUEST FRAME');
        //if (this.renderRequested) {
        //    return this.renderRequested;
        //}
        if (this.renderRequested) {
            await this.renderRequested;
        }
        this.renderRequested = new Promise(succ => {
            this.args.window.requestAnimationFrame(async (timestamp: DOMHighResTimeStamp) => {
                if (timestamp == this.lastFrame) {
                    return;
                }
                this.lastFrame = timestamp;
                try {
                    await this.animationFrame();
                } catch (e) {
                    console.error('FRAME_ERROR:', e);
                } finally {
                    this.renderRequested = undefined;
                    succ();
                }
            });
        });
        return this.renderRequested;
    }
    */

    //------------------------------------------------------------------------
    // Image Controls

    public readonly canvasClickHandler = (event: MouseEvent): boolean => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    public readonly canvasContextmenuHandler = (event: MouseEvent): boolean => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    /*
    private async toggleFullscreen(fullscreen?: boolean): Promise<void> {
        this._args?.toggleFullscreen(fullscreen);
        setImmediate(async () => {
            //await this.resize();
            setImmediate((): void => {
                this.resize(true);
                scrollRangeIntoView(this.ui.viewer);
                //this.scrollToImage();
                this.ui.canvas.focus();
            });
        });
    }
    */

    private async toggleFullscreen(exit = false): Promise<void> {
        if (this._args === undefined || this.forceFullscreen) {
            return;
        }
        if (!this.fullscreen && exit) {
            return;
        }
        this.fullscreen = !this.fullscreen;
        //removeNode(this.ui.canvasPanel);
        if (this.fullscreen && this._args.fullscreenParent) {
            this._args.fullscreenParent.appendChild(this.ui.viewer);
            //]getReqFullscreen().call(this.ui.canvasPanel);
            this.ui.viewer.className = 'viewer-panel-fullscreen config-user000-bg config-user000aaa-text';
            this.ui.viewer.style.zIndex = '9999';
        } else if (this.parentElement) {
            this.appendChild(this.ui.viewer);
            //const after = this._args.after ? this._args.after.nextSibling : null;
            //this._args.parent.insertBefore(this.ui.viewer, after);
            //this.args.parent.appendChild(this.ui.viewer);
            //getExitFullscreen().call(document);
            this.ui.viewer.className = 'viewer-panel config-user000-bg config-user000aaa-text';
        }
        this.resize(true);
        scrollRangeIntoView(this.ui.viewer);
        //this.ui.canvas.focus();
        /*setImmediate(async () => {
            //await this.resize();
            setImmediate((): void => {
                this.resize(true);
                scrollRangeIntoView(this.ui.viewer);
                //this.scrollToImage();
                this.ui.canvas.focus();
            });
        });*/
    }

    public readonly canvasDblclickHandler = async (event: MouseEvent): Promise<void> => {
        this.toggleFullscreen();
        //this.scaleToFit();
        event.preventDefault();
    }

    //------------------------------------------------------------------------
    // Mouse handling

    public readonly canvasMousedownHandler = async (event: MouseEvent): Promise<void> => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        if (!this.scrollable) {
            const button = (event.button === 2) ? this.right : this.left;
            if (button && button.onstart) {
                button.shiftKey = event.shiftKey;
                button.start({x: event.clientX, y: event.clientY}, this.ui.canvas);
            }
        }
    }

    public readonly windowMousemoveHandler = async (event: MouseEvent): Promise<void> => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        event.preventDefault();
        if (this.left && this.left.onstep) {
            this.left.step({x: event.clientX, y: event.clientY}, this.ui.canvas);
        } else if (this.right && this.right.onstep) {
            this.right.step({x: event.clientX, y: event.clientY}, this.ui.canvas);
        } else {
            const rect = this.ui.canvas.getBoundingClientRect();
            const {x, y} = this.scaleTransform.inverse().apply({
                x: (event.clientX - rect.left) * this.scaleX,
                y: (event.clientY - rect.top) * this.scaleY,
            });
            this.emitCommand({type: 'set', id: this.renderer?.img.id, cursor: {x, y}});
        }
    }

    public readonly windowMouseupHandler = async (event: MouseEvent): Promise<void> => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        event.preventDefault();
        if (this.left && this.left.onend) {
            this.left.end();
        } else if (this.right && this.right.onend) {
            this.right.end();
        }
    }

    public readonly canvasMouseWheelHandler = async (event: WheelEvent): Promise<void> => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        event.preventDefault();
        await this.upDown((event.deltaY > 0) ? 1 : (event.deltaY < 0) ? -1 : 0);
    }

    //------------------------------------------------------------------------
    // Touch handling

    private locations: Map<number, Location> = new Map();
    private committedTransform = Transform.identity;
    private transform = Transform.identity;

    private commitTransform(transform: Transform): Transform {
        const domain: [number, number][] = [];
        const range: [number, number][] = [];
        const values = this.locations.values();
        let p = values.next();
        while (!p.done) {
            domain.push([p.value.dx, p.value.dy]);
            range.push([p.value.rx, p.value.ry]);
            p.value.dx = p.value.rx;
            p.value.dy = p.value.ry;
            p = values.next();
        }
        let t;
        if (this.renderer) { // && isDicomRenderer(this.renderer)) {
            t = estimateTranslationScalingRotation(domain, range);
        } else {
            t = estimateTranslationScaling(domain, range);
        }
        return t.multiplyBy(transform);
    }

    private updateTransform(transform: Transform): Transform {
        const domain: [number, number][] = [];
        const range: [number, number][] = [];
        const values = this.locations.values();
        let p = values.next();
        while (!p.done) {
            domain.push([p.value.dx, p.value.dy]);
            range.push([p.value.rx, p.value.ry]);
            p = values.next();
        }

        let t;
        if (this.renderer) { // && isDicomRenderer(this.renderer)) {
            t = estimateTranslationScalingRotation(domain, range);
        } else {
            t = estimateTranslationScaling(domain, range);
        }
        return t.multiplyBy(transform);
    }

    private async startLocation(id: number, x: number, y: number): Promise<void> {
        this.committedTransform = this.commitTransform(this.committedTransform);
        this.transform = this.committedTransform;
        this.locations.set(id, new Location(x, y, x, y));
        this.transform = this.updateTransform(this.committedTransform);
        if (this.renderer) {
            this.emitCommand({
                type: 'set',
                id: this.renderer.img.id,
                slice: this.renderer.index,
                centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                transform: this.transform,
                measures: this.measures.get(this.renderer.index) ?? null,
            });
        }
        this.requestFrame();
    }

    private async moveLocation(id: number, x: number, y: number): Promise<void> {
        const p = this.locations.get(id);
        if (p) {
            p.rx = x;
            p.ry = y;
            this.transform = this.updateTransform(this.committedTransform);
            if (this.renderer) {
                this.emitCommand({
                    type: 'set',
                    id: this.renderer.img.id,
                    slice: this.renderer.index,
                    centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                    width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                    transform: this.transform,
                    measures: this.measures.get(this.renderer.index) ?? null,
                });
            }
            this.requestFrame();
        }
    }

    private endLocation(id: number): void {
        this.committedTransform = this.commitTransform(this.committedTransform);
        this.transform = this.committedTransform;
        this.locations.delete(id);
    }

    private scrollable = false;
    private lastTouch?: number

    public readonly canvasTouchstartHandler = (event: TouchEvent): void => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        if (event.targetTouches.length === event.changedTouches.length) {
            this.scrollable = false;
        }

        if (!this.scrollable) {
            if (event.targetTouches.length === 1) {
                const t2 = event.timeStamp
                    , t1 = this.lastTouch ?? 0
                    , dt = t2 - t1
                    ;
                this.lastTouch = t2;
                if (dt && dt < 500) {
                    this.lastTouch = undefined;
                    this.toggleFullscreen();
                    event.preventDefault();
                }
            } else {
                event.preventDefault();
            }

            if (this.left) {
                switch (this.left.mode) {
                    case 'pan':
                    case 'zoom':
                    case 'rotate':
                        const rect = this.ui.canvas.getBoundingClientRect();
                        for (let i = 0; i < event.changedTouches.length; ++i) {
                            const t = event.changedTouches[i];
                            const {x, y} = this.scaleTransform.inverse().apply({
                                x: (t.clientX - rect.left) * this.scaleX,
                                y: (t.clientY - rect.top) * this.scaleY,
                            });
                            this.startLocation(t.identifier, x, y);
                        }
                        break;
                    default:
                        let x = 0, y = 0;
                        const l = event.targetTouches.length;
                        for (let i = 0; i < l; ++i) {
                            const t = event.targetTouches[i];
                            x += t.clientX;
                            y += t.clientY;
                        }
                        x /= l;
                        y /= l;
                        this.left.start({x, y}, this.ui.canvas);
                        break;
                }
            }
        }

        if (event.targetTouches.length > 1) {
            event.preventDefault();
        }
    }

    public readonly windowTouchmoveHandler = (event: TouchEvent): void => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        this.lastTouch = undefined;
        if (this.fullscreen) {
            event.preventDefault();
        } else if (event.targetTouches.length === 1 && event.changedTouches.length === 1) {
            this.scrollable = true;
            this.locations.clear();
        } else {
            event.preventDefault();
        }

        if (!this.scrollable && this.left) {
            switch (this.left.mode) {
                case 'pan':
                case 'zoom':
                case 'rotate':
                    const rect = this.ui.canvas.getBoundingClientRect();
                    for (let i = 0; i < event.changedTouches.length; ++i) {
                        const t = event.changedTouches[i];
                        const {x, y} = this.scaleTransform.inverse().apply({
                            x: (t.clientX - rect.left) * this.scaleX,
                            y: (t.clientY - rect.top) * this.scaleY,
                        });
                        this.moveLocation(t.identifier, x, y);
                    }
                    break;
                default:
                    let x = 0, y = 0;
                    const l = event.targetTouches.length;
                    for (let i = 0; i < l; ++i) {
                        const t = event.targetTouches[i];
                        x += t.clientX;
                        y += t.clientY;
                    }
                    x /= l;
                    y /= l;
                    this.left.step({x, y}, this.ui.canvas);
                    break;
            }

        }
    }

    public readonly windowTouchendHandler = (event: TouchEvent): void => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        if (!this.scrollable && this.left) {
            switch (this.left.mode) {
                case 'pan':
                case 'zoom':
                case 'rotate':
                    for (let i = 0; i < event.changedTouches.length; ++i) {
                        this.endLocation(event.changedTouches[i].identifier);
                    }
                    break;
                default:
                    this.left.end();
                    break;
            }
            if (this.renderer) {
                this.emitCommand({
                    type: 'set',
                    id: this.renderer.img.id,
                    slice: this.renderer.index,
                    centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                    width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                    transform: this.transform,
                    measures: this.measures.get(this.renderer.index) ?? null,
                });
            }
        }

        if (event.targetTouches.length > 1) {
            event.preventDefault();
        } else if (event.targetTouches.length == 0) {
            this.locations.clear();
        }
        if (event?.target instanceof Node && event?.target === this.ui.canvas) {
            event.preventDefault();
        }
    }

    // Pan Contol

    private static drawRing(cxt: CanvasRenderingContext2D, x: number, y: number, r: number): void {
        cxt.arc(x, y, r - 1, 0, 2 * Math.PI, true);
        cxt.arc(x, y, r + 1, 0, 2 * Math.PI, false);
    }

    private static drawArrow(cxt: CanvasRenderingContext2D): void {
        cxt.moveTo(1, -10);
        cxt.lineTo(1, -15);
        cxt.lineTo(-5, -15);
        cxt.lineTo(0, -20);
        cxt.lineTo(5, -15);
        cxt.lineTo(-1, -15);
        cxt.lineTo(-1, -10);
        cxt.lineTo(1, -10);
    }

    private readonly panStart = (button: Control, startX: number, startY: number) => {
        this.committedTransform = new Transform(this.transform);
        this.postFrame = (context: CanvasRenderingContext2D): void => {
            context.translate(startX, startY);
            context.scale(this.scaleX, this.scaleY);
            context.lineWidth = 1.5;
            context.strokeStyle = '#000000';
            context.fillStyle = '#0f72c3';
            context.beginPath();
            ImageViewerElement.drawRing(context, 0, 0, 5);
            ImageViewerElement.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            ImageViewerElement.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            ImageViewerElement.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            ImageViewerElement.drawArrow(context);
            context.stroke();
            context.fill();
        };
        this.requestFrame();
        const {x: sx, y: sy} = this.scaleTransform.inverse().apply({x: startX, y: startY});
        const panStep = (endX: number, endY: number) => {
            const {x: ex, y: ey} = this.scaleTransform.inverse().apply({x: endX, y: endY});
            this.transform = Transform.identity.translateBy(ex - sx, ey - sy).multiplyBy(this.committedTransform);
            if (this.renderer) {
                this.emitCommand({
                    type: 'set',
                    id: this.renderer.img.id,
                    slice: this.renderer.index,
                    centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                    width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                    transform: this.transform,
                    measures: this.measures.get(this.renderer.index) ?? null,
                    cursor: {x: ex, y: ey}
                });
            }
            this.requestFrame();
        }
        const panEnd = () => {
            this.committedTransform = new Transform(this.transform);
            //console.debug(this.committedTransform, this.transform);
            button.onstep = undefined;
            button.onend = undefined;
            this.postFrame = undefined;
            this.requestFrame();
        }
        button.onstep = panStep;
        button.onend = panEnd;
    }

    // Zoom Control

    private readonly zoomStart = (button: Control, startX: number, startY: number) => {
        this.committedTransform = new Transform(this.transform);
        this.postFrame = (context: CanvasRenderingContext2D): void => {
            context.translate(startX, startY);
            context.scale(this.scaleX, this.scaleY);
            context.lineWidth = 1.5;
            context.strokeStyle = '#000000';
            context.fillStyle = '#0f72c3';
            context.beginPath();
            ImageViewerElement.drawRing(context, 0, 0, 5);
            ImageViewerElement.drawBlackBox(context, 0, -32.5, 20, 15);
            ImageViewerElement.drawBlackBox(context, 0, 28.75, 10, 7.5);
            ImageViewerElement.drawArrow(context);
            context.rotate(Math.PI);
            ImageViewerElement.drawArrow(context);
            context.stroke();
            context.fill();
        };
        this.requestFrame();
        const {x: sx, y: sy} = this.scaleTransform.inverse().apply({x: startX, y: startY});
        const zoomStep = (endX: number, endY: number) => {
            const {x: ex, y: ey} = this.scaleTransform.inverse().apply({x: endX, y: endY});
            const scale = Math.pow(1.01, startY - endY);
            this.transform = Transform.identity.scaleBy(scale, [sx, sy]).multiplyBy(this.committedTransform);
            if (this.renderer) {
                this.emitCommand({
                    type: 'set',
                    id: this.renderer.img.id,
                    slice: this.renderer.index,
                    centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                    width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                    transform: this.transform,
                    measures: this.measures.get(this.renderer.index) ?? null,
                    cursor: {x: ex, y: ey}
                });
            }
            this.requestFrame();
        }
        const zoomEnd = () => {
            this.committedTransform = new Transform(this.transform);
            button.onstep = undefined;
            button.onend = undefined;
            this.postFrame = undefined;
            this.requestFrame();
        }
        button.onstep = zoomStep;
        button.onend = zoomEnd;
    }

    // Rotate Control

    private static drawCircArrow(cxt: CanvasRenderingContext2D, r: number): void {
        const p = -Math.PI / 4.0;
        const q = Math.PI - p;
        cxt.arc(0, 0, r + 1, p, q, false);
        cxt.lineTo((r + 5) * Math.cos(q), (r + 5) * Math.sin(q));
        cxt.lineTo(r * Math.cos(q) - 5 * Math.sin(q), r * Math.sin(q) + 5 * Math.cos(q));
        cxt.lineTo((r - 5) * Math.cos(q), (r - 5) * Math.sin(q));
        cxt.lineTo((r - 1) * Math.cos(q), (r - 1) * Math.sin(q));
        cxt.arc(0, 0, r - 1, q, p, true);
        cxt.lineTo((r - 5) * Math.cos(p), (r - 5) * Math.sin(p));
        cxt.lineTo(r * Math.cos(p) + 5 * Math.sin(p), r * Math.sin(p) - 5 * Math.cos(p));
        cxt.lineTo((r + 5) * Math.cos(p), (r + 5) * Math.sin(p));
        cxt.lineTo((r + 1) * Math.cos(p), (r + 1) * Math.sin(p));
    }

    private readonly rotateStart = (button: Control, startX: number, startY: number) => {
        let startAngle: number | null = null;
        let currentAngle: number | null = null;
        this.committedTransform = new Transform(this.transform);
        this.postFrame = (context: CanvasRenderingContext2D): void => {
            context.translate(startX, startY);
            context.scale(this.scaleX, this.scaleY);
            context.lineWidth = 1.5;
            context.strokeStyle = '#000000';
            context.fillStyle = '#0f72c3';
            context.beginPath();
            ImageViewerElement.drawCircArrow(context, 20);
            context.stroke();
            context.fill();
        };
        this.requestFrame();
        const {x, y} = this.scaleTransform.inverse().apply({x: startX, y: startY});
        const rotateStep = (endX: number, endY: number) => {
            const dx = endX - startX;
            const dy = endY - startY;
            const radius = Math.sqrt(dx * dx + dy * dy);
            if (radius > 20.0) {
                const angle = Math.atan2(dy, dx);
                if (startAngle == null) {
                    if (currentAngle == null) {
                        startAngle = angle;
                    } else {
                        startAngle = angle - currentAngle;
                    }
                }
                currentAngle = angle - startAngle;
            } else {
                startAngle = null;
            }
            if (currentAngle != null) {
                const {x: ex, y: ey} = this.scaleTransform.inverse().apply({x: endX, y: endY});
                this.transform = Transform.identity.rotateBy(currentAngle, [x, y]).multiplyBy(this.committedTransform);
                if (this.renderer) {
                    this.emitCommand({
                        type: 'set',
                        id: this.renderer.img.id,
                        slice: this.renderer.index,
                        centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                        width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                        transform: this.transform,
                        measures: this.measures.get(this.renderer.index) ?? null,
                        cursor: {x: ex, y: ey}
                    });
                }
                this.requestFrame();
            }
        }
        const rotateEnd = () => {
            this.committedTransform = new Transform(this.transform);
            button.onstep = undefined;
            button.onend = undefined;
            this.postFrame = undefined;
            this.requestFrame();
        }
        button.onstep = rotateStep;
        button.onend = rotateEnd;
    }

    // Params Control

    private static drawWhiteBox(cxt: CanvasRenderingContext2D, x: number, y: number, color: string): void {
        cxt.fillStyle = color;
        cxt.moveTo(x - 10, y - 7.5);
        cxt.lineTo(x + 10, y - 7.5);
        cxt.lineTo(x + 10, y + 7.5);
        cxt.lineTo(x - 10, y + 7.5);
        cxt.lineTo(x - 10, y - 7.5);
    }

    private static drawBlackBox(cxt: CanvasRenderingContext2D, x: number, y: number, w = 20, h = 15): void {
        w /= 2.0;
        h /= 2.0;
        cxt.moveTo(x - w, y - h);
        cxt.lineTo(x + w, y - h);
        cxt.lineTo(x + w, y + h);
        cxt.lineTo(x - w, y + h);
        cxt.lineTo(x - w, y - h);
        w -= 2;
        h -= 2;
        cxt.moveTo(x - w, y - h);
        cxt.lineTo(x - w, y + h);
        cxt.lineTo(x + w, y + h);
        cxt.lineTo(x + w, y - h);
        cxt.lineTo(x - w, y - h);
    }

    private static drawDiagonalBox(cxt: CanvasRenderingContext2D, x: number, y: number): void {
        cxt.moveTo(x - 10, y - 7.5);
        cxt.lineTo(x + 10, y - 7.5);
        cxt.lineTo(x + 10, y + 7.5);
        cxt.lineTo(x - 10, y + 7.5);
        cxt.lineTo(x - 10, y - 7.5);
        cxt.moveTo(x - 8, y - 5.5);
        cxt.lineTo(x - 8, y + 5.5);
        cxt.lineTo(x + 8, y - 5.5);
        cxt.lineTo(x - 8, y - 5.5);
    }

    private readonly paramsStart = (button: Control, startX: number, startY: number) => {
        if (!(this.renderer && isDicomRenderer(this.renderer))) { return; }
        const width = 2.0 * this.calcWidth();
        const height = 2.0 * this.calcHeight();
        const startB = this.renderer.brightness;
        const startC = this.renderer.contrast;
        let lastX = startX;
        let lastY = startY;
        this.postFrame = (context: CanvasRenderingContext2D) => {
            context.translate(startX, startY);
            context.scale(this.scaleX, this.scaleY);
            context.lineWidth = 1.5;
            context.strokeStyle = '#000000';
            context.fillStyle = '#0f72c3';
            context.beginPath();
            ImageViewerElement.drawRing(context, 0, 0, 5);
            ImageViewerElement.drawWhiteBox(context, 0, -32.5, '#0f72c3');
            ImageViewerElement.drawBlackBox(context, 0, 32.5);
            ImageViewerElement.drawDiagonalBox(context, -35, 0);
            ImageViewerElement.drawWhiteBox(context, 35, 0, '#083962');
            ImageViewerElement.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            ImageViewerElement.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            ImageViewerElement.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            ImageViewerElement.drawArrow(context);
            context.stroke();
            context.fill();
        };
        this.requestFrame();
        const paramsStep = (endX: number, endY: number) => {
            if (!(this.renderer && isDicomRenderer(this.renderer))) { return; }
            if (endX !== lastX || endY !== lastY) {
                lastX = endX;
                lastY = endY;
                const dx = ((endX - startX) * this.renderer.img.windowWidth) / (2 * width);
                const dy = ((endY - startY) * this.renderer.img.windowCenter) / height;
                this.renderer.brightness = startB + Math.sign(dy) * Math.pow(Math.abs(dy), 1.2);
                this.renderer.contrast = startC + Math.sign(dx) * Math.pow(Math.abs(dx), 1.2);
                if (this.renderer.contrast < 1.0) {
                    this.renderer.contrast = 1.0;
                }
                const {x: ex, y: ey} = this.scaleTransform.inverse().apply({x: endX, y: endY});
                if (this.renderer) {
                    this.emitCommand({
                        type: 'set',
                        id: this.renderer.img.id,
                        slice: this.renderer.index,
                        centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                        width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                        transform: this.transform,
                        measures: this.measures.get(this.renderer.index) ?? null,
                        cursor: {x: ex, y: ey}
                    });
                }
                this.requestRender();
                this.updateWindow();
            }
        }
        const paramsEnd = () => {
            button.onstep = undefined;
            button.onend = undefined;
            this.postFrame = undefined;
            this.requestFrame();
        }
        button.onstep = paramsStep;
        button.onend = paramsEnd;
    }

    // Scrolling

    private drawScroll(cxt: CanvasRenderingContext2D, x: number, y0: number, y1: number, p: number, w = 1, z = 5): void {
        w *= this.scaleY;
        z *= this.scaleY;
        cxt.moveTo(x - w, y0 + w);
        cxt.lineTo(x - w, p + w);
        cxt.lineTo(x - z, p + w);
        cxt.lineTo(x - z, p - w);
        cxt.lineTo(x - w, p - w);
        cxt.lineTo(x - w, y1 - w);
        cxt.lineTo(x + w, y1 - w);
        cxt.lineTo(x + w, p - w);
        cxt.lineTo(x + z, p - w);
        cxt.lineTo(x + z, p + w);
        cxt.lineTo(x + w, p + w);
        cxt.lineTo(x + w, y0 + w);
        cxt.lineTo(x - w, y0 + w);
    }

    private drawText(cxt: CanvasRenderingContext2D, x: number, y: number, t: string): void {
        cxt.strokeStyle = 'black';
        cxt.fillStyle = overlayColour;
        cxt.lineWidth = this.scaleY;
        cxt.lineJoin = "miter";
        cxt.miterLimit = 2;
        cxt.font = `${14 * this.scaleY}px "Noto Sans", Helvetica, Arial, Sans-Serif`;
        cxt.strokeText(t, x, y);
        cxt.fillText(t, x, y);
    }

    private readonly scrollStart = (button: Control, startX: number, startY: number) => {
        if (!(this.renderer && this.renderer.img.frameCount > 1)) {
            return;
        }
        const startI = this.renderer.index;
        const scale = 2 * this.renderer.img.frameCount / (this.ui.canvas as HTMLCanvasElement).height;
        this.postFrame = (context: CanvasRenderingContext2D) => {
            if (!(this.renderer && this.renderer.img.frameCount > 1)) {
                return;
            }
            const y0 = startY + ((this.renderer.img.frameCount - 1) - startI) / scale;
            const y1 = startY - startI / scale;
            const p = startY + (this.renderer.index - startI) / scale;

            //context.setTransform(1, 0, 0, 1, 0, 0);
            context.lineWidth = this.scaleY;
            context.strokeStyle = 'black';
            context.fillStyle = overlayColour;
            context.textBaseline = 'middle';
            context.beginPath();
            this.drawScroll(context, startX, y0, y1, p);
            context.stroke();
            context.fill();
            this.drawText(context, startX + 10 * this.scaleX, y0, this.renderer.img.frameCount.toString());
            this.drawText(context, startX + 10 * this.scaleX, y1, '1');
            if (this.renderer.index > 0 && this.renderer.index < this.renderer.img.frameCount - 1) {
                this.drawText(context, startX + 10 * this.scaleX, p, (this.renderer.index + 1).toString());
            }
        };
        this.requestFrame();
        const scrollStep = (endX: number, endY: number) => {
            if (this.renderer && this.renderer.img.frameCount > 1) {
                const w = this.renderer.index;
                const z = this.renderer.img.frameCount;
                let j = Math.round(startI + (endY - startY) * scale);
                while (j < 0) {
                    j += z;
                }
                while (j >= z) {
                    j -= z;
                }
                if (j !== w) {
                    const {x: ex, y: ey} = this.scaleTransform.inverse().apply({x: endX, y: endY});
                    this.renderer.index = j;
                    if (this.renderer) {
                        this.emitCommand({
                            type: 'set',
                            id: this.renderer.img.id,
                            slice: this.renderer.index,
                            centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                            width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                            transform: this.transform,
                            measures: this.measures.get(this.renderer.index) ?? null,
                            cursor: {x: ex, y: ey}
                        });
                    }
                    this.requestRender();
                    this.updateScroll();
                }
            }
        }
        const scrollEnd = () => {
            button.onstep = undefined;
            button.onend = undefined;
            this.postFrame = undefined;
            this.requestFrame();
        }
        button.onstep = scrollStep;
        button.onend = scrollEnd;
    };

    private getMeasure(p: {x: number, y: number, t: Transform, pixelScale: number}, del: boolean, mode?: string): {measures: MeasureType[], new: boolean} {
        const measures = this.measures.get(this.renderer?.index ?? 1);
        let matches = measures?.reduce((acc, m) => [...acc, ...measurableSelect(m, p)], [] as MeasureType[]);
        if (matches != undefined && matches.length > 0) {
            if (mode === 'poly' || mode === 'point') {
                const lastMeasure = measures?.[measures.length - 1];
                matches = lastMeasure ? measurableSelect(lastMeasure, p) : undefined;
                if (del && lastMeasure?.type === 'poly') {
                    polyRemoveSelected(lastMeasure);
                    matches = [];
                }
                if (matches) {
                    return {measures: matches, new: false}
                }
            } else {
                return {measures: matches, new: false}
            }
        }
        switch (mode) {
            case 'point':
                return {measures: [mkPoint()], new: true}; //[new Coord(args, undefined, this.onSetPoint)], new: true};
            case 'measure':
                return {measures: [mkLine()], new: true};
            case 'ellipse':
                return {measures: [mkEllipse()], new: true};
            case 'rectangle':
                return {measures: [mkRect()], new: true};
            case 'poly':
                const lastMeasure = measures?.[measures.length - 1];
                if (lastMeasure) {
                    const u = p.t.inverse().apply({x: p.x, y: p.y});
                    measurableOrigin(lastMeasure, u);
                    return {measures: [lastMeasure], new: false};
                }
                if (measures && measures.length > 0) {
                    return {measures: [], new: false};
                } else {
                    return {measures: [mkPoly([])], new: true};
                }
            default:
                return {measures: [], new: false};
        }
    }

    private readonly measureStart = (button: Control, x: number, y: number) => {
        const t = this.scaleTransform.multiplyBy(this.transform); //.multiplyBy(this.scaleTransform);
        const {measures: lms, new: newMeasure} = this.getMeasure({x, y, t, pixelScale: this.scaleY}, !button.shiftKey, button.mode);
        if (newMeasure) {
            const lm = lms[0];
            const u = t.inverse().apply({x, y});
            measurableOrigin(lm, u);
            measurablePoint(lm, u);
            const index = this.renderer?.index ?? 1
            const measures = this.measures.get(index);
            if (measures) {
                measures.push(lm);
            } else {
                this.measures.set(index, [lm]);
            }
            this.requestFrame();
        } else {
            if (lms.length > 0) {
                const u = t.inverse().apply({x, y});
                lms.forEach(m => measurablePoint(m, u));
            }
            this.requestFrame();
        }
        const measureStep = (x: number, y: number) => {
            const {x: ex, y: ey} = this.scaleTransform.inverse().apply({x, y});
            const u = this.transform.inverse().apply({x: ex, y: ey});
            lms.forEach(m => measurablePoint(m, u));
            if (this.renderer) {
                this.emitCommand({
                    type: 'set',
                    id: this.renderer.img.id,
                    slice: this.renderer.index,
                    centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                    width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                    transform: this.transform,
                    measures: this.measures.get(this.renderer.index) ?? null,
                    cursor: {x: ex, y: ey}
                });
            }
            this.requestFrame();
        };
        const measureEnd = () => {
            let changed = false;
            for (const lm of lms) {
                if (lm.type === "point") {
                    const p = lm.p;
                    this.onSetPoint?.(p ? {x: Math.round(p.x), y: Math.round(p.y)} : undefined);
                }
                if (measurableSize(lm) < 0.01) {
                    const ms = this.measures.get(this.renderer?.index ?? 1);
                    const ix = ms?.indexOf(lm);
                    if (ix !== undefined) {
                        ms?.splice(ix, 1);
                        changed = true;
                    }
                }
            }
            if (changed) {
                if (this.renderer) {
                    this.emitCommand({
                        type: 'set',
                        id: this.renderer.img.id,
                        slice: this.renderer.index,
                        centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                        width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                        transform: this.transform,
                        measures: this.measures.get(this.renderer.index) ?? null,
                    });
                }
                this.requestFrame();
            }
            button.onstep = undefined;
            button.onend = undefined;
        };
        button.onstep = measureStep;
        button.onend = measureEnd;
    };

    // Control Mode

    private hideDisable(x: Modes): void {
        const pos = findOption(this.ui.control, x);
        if (pos != null) {
            const opt = this.ui.control.options[pos];
            opt.hidden = true;
            opt.disabled = true;
        }
    }

    private updateScroll(): void {
        if (this.renderer && (this.renderer.img.frameCount > 1)) {
            const pos = findOption(this.ui.control, 'scroll');
            if (pos != null) {
                const sel = this.ui.control.options[pos].firstChild;
                if (sel) {
                    sel.textContent = 'scroll (' +
                        (this.renderer.index + 1) + '/' +
                        this.renderer.img.frameCount + ') [s]';
                }
            }
        } else {
            this.hideDisable('scroll');
        }
    }

    private updateWindow(): void {
        if (this.renderer && isDicomRenderer(this.renderer)) {
            const pos = findOption(this.ui.control, 'window');
            if (pos != null) {
                const sel = this.ui.control.options[pos].firstChild;
                if (sel) {
                    sel.textContent = 'window (' +
                        Math.round(this.renderer.brightness) + ' \u00b1 ' +
                        (Math.round(this.renderer.contrast) / 2) + ') [w]';
                }
            }
        } else {
            this.hideDisable('window');
        }
    }

    private updateNotes(): void {
        if (this.renderer && isDicomRenderer(this.renderer)) {
            const pos = findOption(this.ui.control, 'notes');
            if (pos != null) {
                const sel = this.ui.control.options[pos].firstChild;
                if (sel) {
                    sel.textContent =
                        (this.renderer.img.modality ? ('[modality = ' + (this.renderer.img.modality) + ']') : '') +
                        '[size = ' + (Math.ceil(this.renderer.img.frames[this.renderer.index].dataSize / 104851) / 10) + ']';
                }
            }
        } else {
            this.hideDisable('notes');
        }
    }

    private async leftRight(x: number): Promise<void> {
        const t = this.ui.control;
            switch (t.options[t.selectedIndex].value) {
                case 'pan':
                    this.committedTransform = new Transform(this.transform);
                    this.transform = Transform.identity.translateBy(x, 0).multiplyBy(this.committedTransform);
                    if (this.renderer) {
                        this.emitCommand({
                            type: 'set',
                            id: this.renderer.img.id,
                            slice: this.renderer.index,
                            centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                            width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                            transform: this.transform,
                            measures: this.measures.get(this.renderer.index) ?? null,
                        });
                    }
                    this.requestFrame();
                    break;
                case 'zoom': {
                    const canvas = this.ui.canvas;
                    this.committedTransform = new Transform(this.transform);
                    this.transform = Transform.identity.scaleBy((x > 0) ? (1.01 ** x) : (0.99 ** -x),
                        [canvas.width / 2, canvas.height / 2]).multiplyBy(this.committedTransform);
                        if (this.renderer) {
                            this.emitCommand({
                                type: 'set',
                                id: this.renderer.img.id,
                                slice: this.renderer.index,
                                centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                                width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                                transform: this.transform,
                                measures: this.measures.get(this.renderer.index) ?? null,
                            });
                        }
                        this.requestFrame();
                    break;
                }
                case 'rotate': {
                    const canvas = this.ui.canvas;
                    this.committedTransform = new Transform(this.transform);
                    this.transform = Transform.identity.rotateBy(0.01 * x,
                        [canvas.width / 2, canvas.height / 2]).multiplyBy(this.committedTransform);
                        if (this.renderer) {
                            this.emitCommand({
                                type: 'set',
                                id: this.renderer.img.id,
                                slice: this.renderer.index,
                                centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                                width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                                transform: this.transform,
                                measures: this.measures.get(this.renderer.index) ?? null,
                            });
                        }
                        this.requestFrame();
                    break;
                }
                case 'scroll':
                    if (this.renderer && this.renderer.img.frameCount > 1) {
                        const z = this.renderer.img.frameCount;
                        if (z > 0) {
                            let j = (this.renderer.index) + x;
                            while (j < 0) {
                                j += z;
                            }
                            while (j >= z) {
                                j -= z;
                            }
                            if (j != this.renderer.index) {
                                this.renderer.index = j;
                                if (this.renderer) {
                                    this.emitCommand({
                                        type: 'set',
                                        id: this.renderer.img.id,
                                        slice: this.renderer.index,
                                        centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                                        width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                                        transform: this.transform,
                                        measures: this.measures.get(this.renderer.index) ?? null,
                                    });
                                }
                                this.requestRender();
                                this.updateScroll();
                            }
                        }
                    }
                    break;
                case 'window':
                    if (isDicomRenderer(this.renderer)) {
                        this.renderer.contrast += x;
                        if (this.renderer) {
                            this.emitCommand({
                                type: 'set',
                                id: this.renderer.img.id,
                                slice: this.renderer.index,
                                centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                                width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                                transform: this.transform,
                                measures: this.measures.get(this.renderer.index) ?? null,
                            });
                        }
                        this.requestRender();
                        this.updateWindow();
                    }
                    break;
            }
    }

    private async upDown(y: number): Promise<void> {
        const t = this.ui.control;
        switch (t.options[t.selectedIndex].value) {
            case 'pan':
                this.committedTransform = new Transform(this.transform);
                this.transform = Transform.identity.translateBy(0, y).multiplyBy(this.committedTransform);
                if (this.renderer) {
                    this.emitCommand({
                        type: 'set',
                        id: this.renderer.img.id,
                        slice: this.renderer.index,
                        centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                        width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                        transform: this.transform,
                        measures: this.measures.get(this.renderer.index) ?? null,
                    });
                }
                this.requestFrame();
                break;
            case 'zoom': {
                const canvas = this.ui.canvas;
                this.committedTransform = new Transform(this.transform);
                this.transform = Transform.identity.scaleBy((y > 0) ? (1.01 ** y) : (0.99 ** -y),
                    [canvas.width / 2, canvas.height / 2]).multiplyBy(this.committedTransform);
                if (this.renderer) {
                    this.emitCommand({
                        type: 'set',
                        id: this.renderer.img.id,
                        slice: this.renderer.index,
                        centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                        width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                        transform: this.transform,
                        measures: this.measures.get(this.renderer.index) ?? null,
                    });
                }
                this.requestFrame();
                break;
            }
            case 'rotate': {
                const canvas = this.ui.canvas;
                this.committedTransform = new Transform(this.transform);
                this.transform = Transform.identity.rotateBy(0.01 * y,
                    [canvas.width / 2, canvas.height / 2]).multiplyBy(this.committedTransform);
                if (this.renderer) {
                    this.emitCommand({
                        type: 'set',
                        id: this.renderer.img.id,
                        slice: this.renderer.index,
                        centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                        width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                        transform: this.transform,
                        measures: this.measures.get(this.renderer.index) ?? null,
                    });
                }
                this.requestFrame();
                break;
            }
            case 'scroll':
                if (this.renderer && this.renderer.img.frameCount > 1) {
                    const z = this.renderer.img.frameCount;
                    if (z > 0) {
                        let j = (this.renderer.index) + y;
                        while (j < 0) {
                            j += z;
                        }
                        while (j >= z) {
                            j -= z;
                        }
                        if (j != this.renderer.index) {
                            this.renderer.index = j;
                            if (this.renderer) {
                                this.emitCommand({
                                    type: 'set',
                                    id: this.renderer.img.id,
                                    slice: this.renderer.index,
                                    centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                                    width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                                    transform: this.transform,
                                    measures: this.measures.get(this.renderer.index) ?? null,
                                });
                            }
                            this.requestRender();
                            this.updateScroll();
                        }
                    }
                }
                break;
            case 'window':
                if (this.renderer && isDicomRenderer(this.renderer)) {
                    this.renderer.brightness += y;
                    if (this.renderer) {
                        this.emitCommand({
                            type: 'set',
                            id: this.renderer.img.id,
                            slice: this.renderer.index,
                            centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                            width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                            transform: this.transform,
                            measures: this.measures.get(this.renderer.index) ?? null,
                        });
                    }
                    this.requestRender();
                    this.updateWindow();
                }
                break;
        }
    }

    public readonly canvasKeydownHandler = async (event: KeyboardEvent): Promise<void> => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        const n = (event.target as HTMLElement).tagName;
        if (n === 'INPUT' || n === 'TEXTAREA' || this.renderer == null) {
            // catch all keyboard input unless it comes from
            // and INPUT or TEXTAREA.
            return;
        }
        const t = this.ui.control;
        switch (event.key.toLowerCase()) {
            case 'p': {
                event.preventDefault();
                this.setMode('pan');
                break;
            }
            case 'z': {
                event.preventDefault();
                this.setMode('zoom');
                break;
            }
            case 'r': {
                event.preventDefault();
                this.setMode('rotate');
                break;
            }
            case 's': {
                event.preventDefault();
                this.setMode('scroll');
                break;
            }
            case 'w': {
                event.preventDefault();
                this.setMode('window');
                break;
            }
            case 'a': {
                event.preventDefault();
                this.setMode('abdomen');
                break;
            }
            case 'u': {
                event.preventDefault();
                this.setMode('pulmonary');
                break;
            }
            case 'b': {
                event.preventDefault();
                this.setMode('brain');
                break;
            }
            case 'o': {
                event.preventDefault();
                this.setMode('bone');
                break;
            }
            case 'm': {
                event.preventDefault();
                this.setMode('measure');
                break;
            }
            case 'l': {
                event.preventDefault();
                this.setMode('ellipse');
                break;
            }
            case 'c': {
                event.preventDefault();
                this.setMode('rectangle');
                break;
            }
            case 'y': {
                event.preventDefault();
                this.setMode('poly');
                break;
            }
            case 'e': {
                event.preventDefault();
                this.setMode('reset');
                break;
            }
            case 'escape': {
                event.preventDefault();
                if (!this._args?.navigation.getNavigating()) {
                    setImmediate(() => removeNode(this));
                } else {
                    this.toggleFullscreen(false);
                    this.scaleToFit();
                }
                break;
            }
            case 'enter':
                event.preventDefault();
                t.focus();
                break;
            case 'arrowup': {
                event.preventDefault();
                await this.upDown(1);
                break;
            }
            case 'arrowdown': {
                event.preventDefault();
                await this.upDown(-1);
                break;
            }
            case 'arrowleft': {
                event.preventDefault();
                await this.leftRight(-1);
                break;
            }
            case 'arrowright': {
                event.preventDefault();
                await this.leftRight(1);
                break;
            }
        }
    };

    public readonly controlChangeHandler = async (event?: Event): Promise<void> => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        const mode = (this.ui.control.options[this.ui.control.selectedIndex]).value;
        if (isMode(mode)) {
            this.setMode(mode);
        }
        this.ui.control.blur();
        this.ui.canvas.focus();
        if (event) {
            event.preventDefault();
        }
    };

    private shareMode = SHARE_MODE.PRIVATE;

    public readonly broadcastClickHandler = () => {
        switch (this.shareMode) {
            case SHARE_MODE.PRIVATE:
                this.setShareMode(SHARE_MODE.RECEIVE);
                break;
            case SHARE_MODE.RECEIVE:
                this.setShareMode(SHARE_MODE.BROADCAST);
                break;
            case SHARE_MODE.BROADCAST:
                this.setShareMode(SHARE_MODE.PRIVATE)
                break;
        }
    }

    private disableShare = false;
    private privateOnly = false;

    public setDisableShare(disable: boolean) {
        this.disableShare = disable;
    }

    public setPrivateOnly(privateOnly: boolean) {
        this.privateOnly = privateOnly;
        if (privateOnly) {
            this.shareMode = SHARE_MODE.PRIVATE;
        }
    }

    private disablePrivate = false;

    public setDisablePrivate(disable: boolean) {
        if (!this.disableShare) {
            this.disablePrivate = disable;
        }
    }

    public setShareMode(shareMode: SHARE_MODE) {
        this.shareMode = shareMode;
        this.updateShareMode();
    }

    public updateShareMode() {
        console.warn('updateShareMode', SHARE_MODE[this.shareMode]);
        if (this.disableShare) {
            this.ui.broadcast.hidden = true;
            this.ui.broadcast.disabled = true;
            this.ui.control.disabled = false;
            this.ui.reset.disabled = false;
            this.ui.next.disabled = false;
            this.ui.prev.disabled = false;
            replaceIcon(this.ui.broadcastIcon, faEyeSlash);
        } else if (this.privateOnly) {
            this.ui.broadcast.hidden = false;
            this.ui.broadcast.disabled = true;
            this.ui.control.disabled = false;
            this.ui.reset.disabled = false;
            this.ui.next.disabled = false;
            this.ui.prev.disabled = false;
            replaceIcon(this.ui.broadcastIcon, faEyeSlash);
        } else {
            this.ui.broadcast.hidden = false;
            this.ui.broadcast.disabled = false;
            switch (this.shareMode) {
                case SHARE_MODE.RECEIVE:
                    this.ui.control.disabled = true;
                    this.ui.reset.disabled = true;
                    this.ui.next.disabled = true;
                    this.ui.prev.disabled = true;
                    replaceIcon(this.ui.broadcastIcon, faEye);
                    this.emitCommand({type: 'get'});
                    break;
                case SHARE_MODE.BROADCAST:
                    this.ui.control.disabled = false;
                    this.ui.reset.disabled = false;
                    this.ui.next.disabled = false;
                    this.ui.prev.disabled = false;
                    replaceIcon(this.ui.broadcastIcon, faBroadcastTower);
                    if (this.renderer) {
                        this.emitCommand({
                            type: 'set',
                            id: this.renderer.img.id,
                            slice: this.renderer.index,
                            centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                            width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                            transform: this.transform,
                            measures: this.measures.get(this.renderer.index) ?? null,
                        });
                    }
                    break;
                case SHARE_MODE.PRIVATE:
                    if (this.disablePrivate) {
                        this.ui.control.disabled = true;
                        this.ui.reset.disabled = true;
                        this.ui.next.disabled = true;
                        this.ui.prev.disabled = true;
                        replaceIcon(this.ui.broadcastIcon, faEye);
                    } else {
                        this.ui.control.disabled = false;
                        this.ui.reset.disabled = false;
                        this.ui.next.disabled = false;
                        this.ui.prev.disabled = false;
                        replaceIcon(this.ui.broadcastIcon, faEyeSlash);
                    }
                    break;
            }
        }
    }

    public readonly closeClickHandler = (/*event: Event*/): void => {
        if (!this._args?.navigation.getNavigating()) {
            setImmediate(() => removeNode(this));
        }
    };

    public readonly prevClickHandler = () => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        if (this.renderer && this.renderer.img.frameCount > 1) {
            if (this.renderer.index > 0) {
                --this.renderer.index;
                if (this.renderer) {
                    this.emitCommand({
                        type: 'set',
                        id: this.renderer.img.id,
                        slice: this.renderer.index,
                        centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                        width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                        transform: this.transform,
                        measures: this.measures.get(this.renderer.index) ?? null,
                    });
                }
                this.requestRender();
                this.updateScroll();
            }
        }
    }

    public readonly nextClickHandler = () => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        if (this.renderer && this.renderer.img.frameCount > 1) {
            if (this.renderer.index < this.renderer.img.frameCount - 1) {
                ++this.renderer.index;
                if (this.renderer) {
                    this.emitCommand({
                        type: 'set',
                        id: this.renderer.img.id,
                        slice: this.renderer.index,
                        centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                        width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                        transform: this.transform,
                        measures: this.measures.get(this.renderer.index) ?? null,
                    });
                }
                this.requestRender();
                this.updateScroll();
            }
        }
    }

    public readonly expandClickHandler = () => {
        if (this.fullscreen) {
            replaceIcon(this.ui.expandIcon, faExpand);
        } else {
            replaceIcon(this.ui.expandIcon, faCompress);
        }
        this.toggleFullscreen();
        this.scaleToFit();
    }

    public readonly resetClickHandler = async (): Promise<void> => {
        if (this.shareMode === SHARE_MODE.RECEIVE) {
            return;
        }
        this.resetView();
        this.scaleToFit();
        if (this.renderer) {
            this.emitCommand({
                type: 'set',
                id: this.renderer.img.id,
                slice: this.renderer.index,
                centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                transform: this.transform,
                measures: this.measures.get(this.renderer.index) ?? null,
            });
        }
        this.requestRender();
        this.updateScroll();
        this.updateWindow();
    };

    private resetView(): void {
        this.measures.clear();
        this.onSetPoint?.();
        this.committedTransform = Transform.identity;
        this.transform = Transform.identity;
        if (this.renderer) {
            if (this.renderer.img.frameCount > 1) {
                this.renderer.index = 0;
            }
            if (isDicomRenderer(this.renderer)) {
                this.renderer.contrast = this.renderer.img.windowWidth;
                this.renderer.brightness = this.renderer.img.windowCenter;
            }
        }
    }

    //------------------------------------------------------------------------
    // Window Resizing

    private getPixelScale(): void {
        this.scaleX = window.devicePixelRatio || 1.0;
        this.scaleY = window.devicePixelRatio || 1.0;
        if (window.visualViewport && window.visualViewport.scale != 1) {
            this.scaleX *= window.visualViewport.scale;
            this.scaleY *= window.visualViewport.scale;
        } else if (detectBrowser() == 'safari/webkit') {
            this.scaleX *= document.documentElement.clientWidth / window.innerWidth;
            this.scaleY *= document.documentElement.clientHeight / window.innerHeight;
            /*
            if (window.outerWidth && window.outerHeight) {
                this.scaleX *= window.outerWidth / window.innerWidth;
                this.scaleY *= window.outerHeight / window.innerHeight;
            } else { // no outer dimensions = mobile
                let sw = screen.width;
                let sh = screen.height;
                if ((screen.width > screen.height) != (window.innerWidth > window.innerHeight)) {
                    sw = screen.height;
                    sh = screen.width;
                }
                this.scaleX *= sw / window.innerWidth;
                this.scaleY *= sh / window.innerHeight;
            }
            */
        }

        //this.scaleX = Math.min(this.scaleX, this.scaleY);
        //this.scaleY = this.scaleX;

        this.left.scaleX = this.scaleX;
        this.left.scaleY = this.scaleY;
        this.right.scaleX = this.scaleX;
        this.right.scaleY = this.scaleY;
    }

    private calcWidth(): number {
        return this.ui.canvasPanel.scrollWidth;
        /*if (this.fullscreen) {
            return this.args.window.innerWidth || this.args.window.document.documentElement.clientWidth ||
                this.args.window.document.body.clientWidth;
        } else if (this.args.parent) {
            return this.args.parent.clientWidth;// - 3.2 * this.rem;
        } else {
            throw 'CALC_WIDTH';
        }*/
    }

    private calcHeight(): number {
        return this.ui.canvasPanel.scrollHeight;
        /*if (this.fullscreen) {
            return this.args.window.innerHeight || this.args.window.document.documentElement.clientHeight ||
                this.args.window.document.body.clientHeight;
        } else if (this.args.sizeReference) {
            return this.args.sizeReference.clientHeight;// - 3.2 * this.rem;
        } else {
            throw 'CALC_HEIGHT';
        }*/
    }

    private calcRect(): { width: number; height: number } {
        return { width: this.calcWidth(), height: this.calcHeight() };
    }

    private scale(width: number, height: number): void {
        if (this.renderer && this.renderer.img.cols && this.renderer.img.rows) {
            const rows = this.renderer.img.rows
            , cols = this.renderer.img.cols
            , scale = Math.min(width / cols, height / rows)
            ;

            const x = width - scale * cols;
            const y = height - scale * rows;

            if (this.renderer instanceof PdfRenderer && !this.fullscreen) {
                this.scaleTransform = Transform.identity.scaleBy(scale).translateBy(x/2, 0);
            } else {
                this.scaleTransform = Transform.identity.scaleBy(scale).translateBy(x/2, y/2);
            }
            //this.scaleTransform = Transform.identity.scaleBy(scale);

            //this.scaleTransform = Transform.identity.scaleBy(scale).multiplyBy(this.transform.inverse());
            //this.scaleTransform = Transform.identity.translateBy(cols/2, rows/2).scaleBy(scale)).multiplyBy(this.transform.inverse());
        } else {
            console.error('SCALE FAIL', this.renderer, this.renderer?.img.cols, this.renderer?.img.rows);
        }
    }

    /*
    private scaleToFit(): void {
        if (this.renderer && this.renderer.img.cols != null && this.renderer.img.rows != null) {
            const width = this.calcWidth() * this.scaleX
                , height = this.calcHeight() * this.scaleY
                , rows = this.renderer.img.rows
                , cols = this.renderer.img.cols
                , scale = Math.min(width / cols, height / rows)
                ;

            this.scaleTransform = Transform.identity.scaleBy(scale, [width / 2, height / 2]);
        }
    }
    */

    public scaleToFit(): void {
        this.getPixelScale();
        const r = this.calcRect();
        const width = Math.floor(r.width * this.scaleX);
        const height = Math.floor(r.height * this.scaleY);
        this.scale(width, height);
    }

    private resizeCanvas(force: boolean, width?: number, height?: number): boolean {
        if (!(this.renderer && this.renderer.img.rows && this.renderer.img.cols)) {
            console.error('RENDERER IMAGE SIZE UNINITIALISED');
            return false;
        }

        if (width === undefined || height === undefined) {
            const r = this.calcRect();
            width = r.width;
            height = r.height;
        }

        if (this.renderer instanceof PdfRenderer && !this.fullscreen && this.renderer.viewport) {
            console.debug('PDF VIEW SIZING ENABLED', this.renderer.viewport.width, this.renderer.viewport.height);
            height = width * this.renderer.viewport.height / this.renderer.viewport.width;
            if (this.ui.canvasPanel.clientHeight !== height) {
                console.debug('CANVAS PANEL RESIZED', height);
                this.ui.canvasPanel.style.height = `${Math.round(height)}px`;
            }
        }

        if (this.compact) {
            console.debug('COMPACT VIEW SIZING ENABLED', this.renderer.img.cols, this.renderer.img.rows);
            height = width * this.renderer.img.rows / this.renderer.img.cols;
            if (this.ui.canvasPanel.clientHeight !== height) {
                console.debug('CANVAS PANEL RESIZED', height);
                this.ui.canvasPanel.style.height = `${Math.round(height)}px`;
            }
        }

        this.getPixelScale();
        const innerWidth = Math.floor(width * this.scaleX);
        const innerHeight = Math.floor(height * this.scaleY);

        console.debug(this.ui.canvas.width + ' x ' + this.ui.canvas.height + ' = ' + innerWidth + ' x ' + innerHeight);
        if (force ||
            this.ui.canvas.width !== innerWidth ||
            this.ui.canvas.height !== innerHeight
        ) {
            //force && console.log('FORCE');
            this.ui.canvas.style.width = `${width}px`;
            this.ui.canvas.style.height = `${height}px`;
            this.ui.canvas.width = innerWidth;
            this.ui.canvas.height = innerHeight;
            this.scale(innerWidth, innerHeight);
            return true;
        }
        return false;
    }

    private async resize(force = false, width?: number, height?: number): Promise<void> {
        //console.log('RESIZE');
        if (this.resizeCanvas(force, width, height)) {
            await this.animationFrame();
        }
    }

    /*private scrollToImage(): void {
        const parent = this.fullscreen ? this.args.fullscreenParent : this.args.scrollContainer
        if (parent && parent.scrollHeight > parent.clientHeight) {
            //this.args.parent.scrollTo(0, (this.ui.canvas_panel as HTMLCanvasElement).offsetTop);
            parent.scrollTop = this.ui.canvasPanel.offsetTop;
        } else if (parent && parent.parentElement && parent.parentElement.scrollHeight > parent.parentElement.clientHeight) {
            //this.args.parent.parentElement.scrollTo(0, (this.ui.canvas_panel as HTMLCanvasElement).offsetTop - 1.6 * this.rem);
            parent.parentElement.scrollTop = this.ui.canvasPanel.offsetTop - 1.6 * this.rem;
        }
    }*/

    public readonly handleResize = (entries: ResizeObserverEntry[]): void => {
        const l = entries.length;
        if (l > 0) {
            const {width, height} = entries[l-1].contentRect;
            //console.log('NEWSIZE', width, height);
            this.resize(false, Math.floor(width), Math.floor(height));
        }
    }

    public set disabled(isDisabled: boolean) {
        console.log('IMAGE_VIEWER_DISABLED', isDisabled);
        this.ui.close.disabled = isDisabled;
    }

    public async setDicom(renderer?: Renderer): Promise<void> {
        if (!this._args) {
            throw new DOMException('args not defined', 'InvalidStateError');
        }
        //try {
        if (this.requestedAnimationFrame !== undefined) {
            window.cancelAnimationFrame(this.requestedAnimationFrame);
        }
        this.measures.clear();
        if (renderer === this.renderer) {
            return;
        }
        if (this.renderer) {
            this.renderer.destroy();
        }
        this.renderer = renderer;
        if (!renderer) {
            this.requestFrame();
            return;
        }
        //console.log('SET DICOM ' + renderer.img.frames.toString());
        this.postFrame = undefined;
        this.ui.progress.style.width = (100.0 / (renderer.img.frameCount + 1)) + "%"
        if (renderer.img.caption) {
            this.ui.tspan.innerHTML = renderer.img.caption;
        } else {
            this.ui.tspan.innerHTML = '&nbsp;'
        }
        //if (isDicomRenderer(renderer)) { // } && renderer.img.windowWidth <= 0) {
            //dicomMinMax(renderer.img, renderer.index);
            //console.debug(`SET_DICOM_WINDOW ${renderer.img.windowCenter}~${renderer.img.windowWidth}`);
            //renderer.brightness = renderer.img.windowCenter;
            //renderer.contrast = renderer.img.windowWidth;
        //}
        const sel = this.ui.control;
        removeChildren(sel);
        /*if (this.args && this.args.noMouse) {
            if (isDicomRenderer(renderer) || renderer.img.frames > 1) {
                mkNode('text', { text: 'pan/zoom/rotate [p]', parent: mkNode('option', { parent: sel, attrib: { value: 'pan' } }) });
                this.ui.control.style.display = 'block';
                this.ui.reset.style.display = 'none';
            } else {
                this.ui.control.style.display = 'none';
                this.ui.reset.style.display = 'block';
            }
        } else {
        */
            mkNode('text', { text: 'pan [p]', parent: mkNode('option', { parent: sel, attrib: { value: 'pan' } }) });
            mkNode('text', { text: 'zoom [z]', parent: mkNode('option', { parent: sel, attrib: { value: 'zoom' } }) });
            mkNode('text', { text: 'rotate [r]', parent: mkNode('option', { parent: sel, attrib: { value: 'rotate' } }) });
            this.ui.control.hidden = false;
            //this.ui.reset.hidden = true;
        //}
        //console.log('IMG', renderer.img);
        this.ui.expand.hidden = this._args?.fullscreenParent === undefined;
        if (renderer.img.frameCount > 1) {
            this.ui.next.hidden = false;
            this.ui.prev.hidden = false;
            mkNode('text', { text: 'scroll [s]', parent: mkNode('option', { parent: sel, attrib: { value: 'scroll' } }) });
        } else {
            this.ui.next.hidden = true;
            this.ui.prev.hidden = true;
        }
        this.updateScroll();
        if (isDicomRenderer(renderer)) {
            mkNode('text', { text: 'window [w]', parent: mkNode('option', { parent: sel, attrib: { value: 'window' } }) });
            if (renderer.img.modality?.toUpperCase() === 'CT') {
                mkNode('text', { text: 'window = abdomen [a]', parent: mkNode('option', { parent: sel, attrib: { value: 'abdomen' } }) });
                mkNode('text', { text: 'window = pulmonary [u]', parent: mkNode('option', { parent: sel, attrib: { value: 'pulmonary' } }) });
                mkNode('text', { text: 'window = brain [b]', parent: mkNode('option', { parent: sel, attrib: { value: 'brain' } }) });
                mkNode('text', { text: 'window = bone [o]', parent: mkNode('option', { parent: sel, attrib: { value: 'bone' } }) });
            }
            this.updateWindow();
            mkNode('text', { text: 'measure [m]', parent: mkNode('option', { parent: sel, attrib: { value: 'measure' } }) });
            mkNode('text', { text: 'ellipse [l]', parent: mkNode('option', { parent: sel, attrib: { value: 'ellipse' } }) });
            mkNode('text', { text: 'rectangle [c]', parent: mkNode('option', { parent: sel, attrib: { value: 'rectangle' } }) });
            mkNode('text', { text: 'reset [e]', parent: mkNode('option', { parent: sel, attrib: { value: 'reset' } }) });
            mkNode('text', { text: '-', parent: mkNode('option', { parent: sel, attrib: { value: 'notes', disabled: 'true' } }) });
            this.updateNotes();
        } else {
            mkNode('text', { text: 'reset [e]', parent: mkNode('option', { parent: sel, attrib: { value: 'reset' } }) });
        }
        if (renderer.img.frameCount > 1) {
            this.left.mode = 'scroll';
            this.left.onstart = this.scrollStart;
            const pos = findOption(sel, 'scroll');
            if (pos != null) {
                sel.selectedIndex = pos;
            }
        } else {
            this.left.mode = 'pan';
            this.left.onstart = this.panStart;
            const pos = findOption(sel, 'pan');
            if (pos != null) {
                sel.selectedIndex = pos;
            }
        }
        //this.ui.title.style.display = 'block';
        //this.ui.canvasPanel.style.display = 'block';
        //this.resizeCanvas(true);
        //this.scrollToImage();
        //this.ui.canvas.focus();
        await renderer.load(
            this._args.resources /*?? {
               getImageBegin: async () => Promise.resolve(),
               getImageFrame: async () => undefined,
               getImageEnd: async () => Promise.resolve(),
            }*/,
            async () => {
                //this.ui.title.style.display = 'block';
                this.ui.canvasPanel.style.display = 'flex';
                this.resizeCanvas(true);
                this.scaleToFit();
                //scrollRangeIntoView(this.ui.canvasPanel);
                //this.scrollToImage();
                this.ui.canvas.focus();
                this.requestRender()
                if (this.parentElement) {
                    scrollRangeIntoView(this.parentElement);
                }
            },
            (p: number) => {
                this.ui.progress.style.width = p + '%';
            }
        );
        //this.resizeCanvas(true);
        //this.scrollToImage();
        //this.ui.canvas.focus();
        //this.emitCommand({type: 'get'});
        //this.setShareMode(SHARE_MODE.PRIVATE);
        await wait(300);
        this.ui.progress.style.width = '0%';
    }

    // Clean up

    private async destroy(): Promise<void> {
        //console.log('DESTROY');
        if (this.requestedAnimationFrame !== undefined) {
            window.cancelAnimationFrame(this.requestedAnimationFrame);
        }
        if (this.renderer) {
            this.renderer.destroy();
        }
        this.renderer = undefined;
        this.left.clear();
        this.right.clear();
        this.fullscreen = false;
        removeNode(this.ui.viewer);
        //removeNode(this.ui.canvasPanel);
        //removeNode(this.ui.title);
        /*if (this.visibilityChange) {
            window.removeEventListener(this.visibilityChange, this.visibilityHandler);
        }*/
        const passive: AddEventListenerOptions & EventListenerOptions = { passive: true };
        const notPassive: AddEventListenerOptions & EventListenerOptions = { passive: false };
        //window.removeEventListener('beforeunload', this.unloadHandler);
        this.ui.canvas.removeEventListener('click', this.canvasClickHandler);
        this.ui.canvas.removeEventListener('dblclick', this.canvasDblclickHandler);
        this.ui.canvas.removeEventListener('mousedown', this.canvasMousedownHandler);
        this.ui.canvas.removeEventListener('touchstart', this.canvasTouchstartHandler, notPassive);
        //this.ui.canvas.removeEventListener('wheel', this.canvasMouseWheelHandler, notPassive);
        this.ui.control.removeEventListener('change', this.controlChangeHandler);
        this.ui.broadcast.removeEventListener('click', this.broadcastClickHandler, passive);
        this.ui.close.removeEventListener('click', this.closeClickHandler, passive);
        this.ui.prev.addEventListener('click', this.prevClickHandler, passive);
        this.ui.next.addEventListener('click', this.nextClickHandler, passive);
        this.ui.expand.addEventListener('click', this.expandClickHandler, passive);
        this.ui.reset.removeEventListener('click', this.resetClickHandler, passive);
        this.ui.canvas.removeEventListener('keydown', this.canvasKeydownHandler, notPassive);
        window.removeEventListener('contextmenu', this.canvasContextmenuHandler);
        window.removeEventListener('mousemove', this.windowMousemoveHandler);
        window.removeEventListener('touchmove', this.windowTouchmoveHandler, notPassive);
        window.removeEventListener('mouseup', this.windowMouseupHandler);
        window.removeEventListener('touchend', this.windowTouchendHandler, notPassive);
        //this.args.window.removeEventListener('resize', this.windowResizeHandler);
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (typeof this.closeHook === 'function') {
            this.closeHook();
            this.closeHook = undefined;
        }
        //console.log('DESTROY DONE');
    }

    setPolyAndPoint(data: unknown, p: unknown) {
        if (isPoly(data) && isPoint(p)) {
            const measures = [];
            if (data.length > 0) {
                measures.push(mkPoly(data));
            }
            measures.push(mkPoint(p));
            this.measures.set(this.renderer?.index ?? 0, measures);
            this.requestFrame();
        } else {
            console.error(`Poly format error:`, data);
        }
    }

    private onSetPoint?: (_?: {x:number, y:number}) => Promise<void>;

    setPoint(p?: {x: number, y: number}, onchange?: (_?: {x:number, y:number}) => Promise<void>) {
        this.onSetPoint = onchange;
        this.measures.set(this.renderer?.index ?? 0, [mkPoint(p)]); // new Coord(args, p, onchange)]);
        console.debug('MEASURES', this.measures);
        const pos = addOption(this.ui.control, 'point', (parent, value) => mkNode('text', { text: 'point [i]', parent: mkNode('option', { parent, attrib: { value } }) }));
        if (pos != null) {
            this.ui.control.selectedIndex = pos;
            this.controlChangeHandler();
        }
        this.requestFrame();
    }

    setPoly(data: unknown): void {
        if (isPoly(data)) {
            console.debug('SET_POLY', data);
            this.measures.set(this.renderer?.index ?? 0, (data.length > 0) ? [mkPoly(data)] : []);
            console.debug('MEASURES', this.measures);
            const pos = addOption(this.ui.control, 'poly', (parent, value) => mkNode('text', { text: 'poly [y]', parent: mkNode('option', { parent, attrib: { value } }) }));
            if (pos != null) {
                this.ui.control.selectedIndex = pos;
                this.controlChangeHandler();
            }
            this.requestFrame();
        } else {
            console.error(`Poly format error:`, data);
        }
    }

    getPoly(): [number, number][] {
        console.debug('GET_POLY');
        const measures = this.measures.get(this.renderer?.index ?? 0) ?? null;
        console.debug('MEASURES', measures);
        if (measures) {
            return measurablePoints(measures[0]);
        } else {
            return [];
        }
    }

    private setMode(mode: Modes) {
        if (this.renderer != null) {
            switch (mode) {
                case 'pan': {
                    this.left.mode = 'pan';
                    this.left.onstart = this.panStart;
                    break;
                }
                case 'zoom': {
                    this.left.mode = 'zoom';
                    this.left.onstart = this.zoomStart;
                    break;
                }
                case 'rotate': {
                    this.left.mode = 'rotate';
                    this.left.onstart = this.rotateStart;
                    break;
                }
                case 'scroll': {
                    this.left.mode = 'scroll';
                    this.left.onstart = this.scrollStart;
                    break;
                }
                case 'window': {
                    this.left.mode = 'window';
                    this.left.onstart = this.paramsStart;
                    this.requestRender();
                    this.updateWindow();
                    break;
                }
                case 'abdomen': {
                    if (isDicomRenderer(this.renderer)) {
                        this.left.mode = 'window';
                        this.left.onstart = this.paramsStart;
                        this.renderer.brightness = 150;
                        this.renderer.contrast = 500;
                        if (this.renderer) {
                            this.emitCommand({
                                type: 'set',
                                id: this.renderer.img.id,
                                slice: this.renderer.index,
                                centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                                width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                                transform: this.transform,
                                measures: this.measures.get(this.renderer.index) ?? null,
                            });
                        }
                        this.requestRender();
                        this.updateWindow();
                    }
                    break;
                }
                case 'pulmonary': {
                    if (isDicomRenderer(this.renderer)) {
                        this.left.mode = 'window';
                        this.left.onstart = this.paramsStart;
                        this.renderer.brightness = -500;
                        this.renderer.contrast = 1500;
                        if (this.renderer) {
                            this.emitCommand({
                                type: 'set',
                                id: this.renderer.img.id,
                                slice: this.renderer.index,
                                centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                                width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                                transform: this.transform,
                                measures: this.measures.get(this.renderer.index) ?? null,
                            });
                        }
                        this.requestRender();
                        this.updateWindow();
                    }
                    break;
                }
                case 'brain': {
                    if (isDicomRenderer(this.renderer)) {
                        this.left.mode = 'window';
                        this.left.onstart = this.paramsStart;
                        this.renderer.brightness = 40;
                        this.renderer.contrast = 80;
                        if (this.renderer) {
                            this.emitCommand({
                                type: 'set',
                                id: this.renderer.img.id,
                                slice: this.renderer.index,
                                centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                                width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                                transform: this.transform,
                                measures: this.measures.get(this.renderer.index) ?? null,
                            });
                        }
                        this.requestRender();
                        this.updateWindow();
                    }
                    break;
                }
                case 'bone': {
                    if (isDicomRenderer(this.renderer)) {
                        this.left.mode = 'window';
                        this.left.onstart = this.paramsStart;
                        this.renderer.brightness = 570;
                        this.renderer.contrast = 3000;
                        if (this.renderer) {
                            this.emitCommand({
                                type: 'set',
                                id: this.renderer.img.id,
                                slice: this.renderer.index,
                                centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                                width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                                transform: this.transform,
                                measures: this.measures.get(this.renderer.index) ?? null,
                            });
                        }
                        this.requestRender();
                        this.updateWindow();
                    }
                    break;
                }
                case 'point': {
                    this.left.mode = 'point';
                    this.left.onstart = this.measureStart;
                    break;
                }
                case 'measure': {
                    this.left.mode = 'measure';
                    this.left.onstart = this.measureStart;
                    break;
                }
                case 'ellipse': {
                    this.left.mode = 'ellipse';
                    this.left.onstart = this.measureStart;
                    break;
                }
                case 'rectangle': {
                    this.left.mode = 'rectangle';
                    this.left.onstart = this.measureStart;
                    break;
                }
                case 'poly': {
                    this.left.mode = 'poly';
                    this.left.onstart = this.measureStart;
                    break;
                }
                case 'reset': {
                    this.resetView();
                    this.scaleToFit();
                    if (this.renderer) {
                        this.emitCommand({
                            type: 'set',
                            id: this.renderer.img.id,
                            slice: this.renderer.index,
                            centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                            width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                            transform: this.transform,
                            measures: this.measures.get(this.renderer.index) ?? null,
                        });
                    }
                    this.requestRender();
                    this.updateScroll();
                    this.updateWindow();
                    break;
                }
                case 'close': {
                    if (!this._args?.navigation.getNavigating()) {
                        setImmediate(() => removeNode(this));
                    }
                    break;
                }
            }
            if (this.left.mode) {
                const x = findOption(this.ui.control, this.left.mode);
                if (x != null) {
                    this.ui.control.selectedIndex = x;
                }
            }
        }
    }

    public applyCommand(command: ImageCommand) {
        if (this.shareMode === SHARE_MODE.PRIVATE) {
            return;
        }
        if (this.shareMode === SHARE_MODE.BROADCAST) {
            this.setShareMode(SHARE_MODE.RECEIVE);
        }
        switch (command.type) {
        case 'set':
            let frame = false, render = false;
            this.cursor = command.cursor;
            if (command.mode) {
                this.setMode(command.mode);
            }
            if (command.slice !== undefined && this.renderer) {
                this.renderer.index = command.slice;
                render = true;
                this.updateScroll();
            }
            if ((command.centre !== undefined) && (command.width !== undefined) && isDicomRenderer(this.renderer)) {
                this.renderer.brightness = command.centre;
                this.renderer.contrast = command.width;
                render = true;
                this.updateWindow();
            }
            if (command.transform !== undefined) {
                const transform = command.transform;
                Object.setPrototypeOf(transform, Transform.prototype);
                this.transform = transform;
                frame = true;
            }
            if (isDicomRenderer(this.renderer)) {
                if (command.measures === null) {
                    this.measures.delete(this.renderer.index);
                } else if (command.measures !== undefined) {
                    this.measures.set(this.renderer.index, command.measures);
                }
                frame = true;
            }
            if (render) {
                this.left.onstep = undefined;
                this.left.onend = undefined;
                this.postFrame = undefined;
                this.requestRender();
            } else if (frame) {
                this.left.onstep = undefined;
                this.left.onend = undefined;
                this.postFrame = undefined;
                this.requestFrame();
            } else if (command.cursor !== undefined) {
                this.requestFrame();
            }
            break;
        case 'get':
            if (this.renderer) {
                this.emitCommand({
                    type: 'set',
                    id: this.renderer.img.id,
                    slice: this.renderer.index,
                    centre: isDicomRenderer(this.renderer) ? this.renderer.brightness : undefined,
                    width: isDicomRenderer(this.renderer) ? this.renderer.contrast : undefined,
                    transform: this.transform,
                    measures: this.measures.get(this.renderer.index) ?? null,
                });
            }
            break;
        }
    }

    private observers: Set<ImageCmdObserver> = new Set;

    public addCommandObserver(observer: ImageCmdObserver) {
        this.observers.add(observer);
    }

    public removeCommandObserver(observer: ImageCmdObserver) {
        this.observers.delete(observer)
    }

    private emitCommand(cmd: ImageCommand) {
        if (cmd.type === 'set') {
            cmd.mode = this.left.mode;
        }
        if (this.shareMode === SHARE_MODE.BROADCAST) {
            this.observers.forEach(x => x.commandObservation(cmd));
        }
    }
}

function isPoly(p: unknown): p is [number, number][] {
    return Array.isArray(p) && p.every(x =>
        Array.isArray(x) && x.length === 2 && x.every(y => typeof y === 'number')
    );
}

window.customElements.define('image-viewer', ImageViewerElement);

declare global {
    interface HTMLElementTagNameMap {
     'image-viewer': ImageViewerElement;
   }
}
