import { makeElements, removeChildren, NodeSpecObject, safeJsonParse, isIndexed, getFreeSpace } from './utils';
import { ExamMap, getProctors, proctorExamity, ExamItem } from '@p4b/exam-service';
import { ExamId } from '@p4b/utils-zip';
import { translate } from '@p4b/utils-lang';
import { ReconnectingEventSource } from '@p4b/utils-events';
import { configGhostPrimaryButton, configSolidPrimaryButton, configInfoPanel, configErrorPanel, configNtrlTextBox } from '@p4b/exam-accessibility';
import { dbGet, dbPut } from '@p4b/utils-db';
import { alertModal } from "@p4b/modal-dialog";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
    interface EventSourceEventMap {
        'exam-started': MessageEvent;
        'examlist-changed': MessageEvent;
    }
}

interface ExamSelectUi {
    examSelect: HTMLDivElement;
    examLogo: HTMLDivElement;
    examBodyBox: HTMLDivElement;
    examInfo: HTMLDivElement;
    examText: HTMLDivElement;
    examInstructions: HTMLDivElement;
    examProctor: HTMLInputElement;
    examChoiceLabel: HTMLLabelElement;
    examChoiceDiv: HTMLDivElement;
    examChoice: HTMLSelectElement;
    examChoiceIcon: HTMLSpanElement;
    examPinLabel: HTMLLabelElement;
    examPinText: HTMLSpanElement;
    examPin: HTMLInputElement;
    pinError: HTMLDivElement;
    examButtons: HTMLDivElement;
    examGo: HTMLInputElement;
    examBack: HTMLInputElement;
}

function examSelectUi({cid} : {cid: string}): NodeSpecObject<ExamSelectUi> {
    const instance = window.location.host.replace(/practique\.net(:[0-9]+)?$/, '');
    return {
        examSelect: { elem: 'div', className: 'login-panel config-user100aaa-text' },
        examLogo: {
            elem: 'div', className: 'login-risr-box config-info900-bg', parent: 'examSelect', children: [{
                elem: 'img',
                className: 'login-risr-logo',
                src: 'risr-assess-on-dark.png',
                tip: instance,
                attrib: {
                    alt: instance,
                    draggable: 'false',
                }
            }]
        },
        examBodyBox: {
            elem: 'div',
            className: 'login-body-box config-user000-alpha90',
            parent: 'examSelect',
            style: {flexDirection: 'column'}
        },
        examInfo: {
            elem: 'div', parent: 'examBodyBox', className: `login-error ${configInfoPanel}`, children: [
                {elem: 'html', html: translate('CHOOSE_INSTRUCTIONS', {cid})}
            ]
        },
        examInstructions: {
            elem: 'div', className: 'login-header', parent: 'examBodyBox', style: {
                //fontSize: '1.32rem',
                //fontWeight: 'bold',
                //margin: '0 0 2rem 0',
                padding: '0', //1rem 0 0 0',
            }
        },
        pinError: {
            elem: 'div', parent: 'examBodyBox', className: `login-error ${configErrorPanel}`, style: {
                marginBottom: '16px',
            }
        },
        examChoiceLabel: {
            elem: 'label', className: 'login-label', parent: 'examBodyBox',
            children: [
                { elem: 'span', children: [
                    { elem: 'text', text: translate('CHOOSE_EXAM_LABEL') }
                ]}
            ]
        },
        examChoiceDiv: {elem: 'div', parent: 'examChoiceLabel', style: {
            position: 'relative',
            margin: '0 0 1rem 0',
            padding: '0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'stretch',
        }},
        examChoice: { elem: 'select', parent: 'examChoiceDiv', className: `login-field ${configNtrlTextBox}`, style: {
            paddingRight: '2rem',
            margin: '0',
            width: '100%',
        }},
        examChoiceIcon: {
            elem: 'div', className: 'config-user000-bg config-user000aa-text', parent: 'examChoiceDiv', style: {
                background: 'transparent',
                pointerEvents: 'none',
                position: 'absolute',
                top: '0',
                right: '0',
                bottom: '0',
                display: 'flex',
                alignItems: 'center',
                paddingRight: '0.64rem'
            }, children: [
                {elem: 'text', text: '\u25bc'},
            ]
        },
        examPinLabel: {
            elem: 'label', className: 'login-label', parent: 'examBodyBox',
            //children: [
            //    { elem: 'text', text: translate('CHOOSE_PIN_LABEL') }
            //]
        },
        examPinText: {
            elem: 'span', parent: 'examPinLabel'
        },
        examPin: {
            elem: 'input', parent: 'examPinLabel', className: `login-field ${configNtrlTextBox}`,
            attrib: { type: 'text', spellcheck: "false", autocorrect: 'off', autocapitalize: 'none', autocomplete: 'exam-pin' }
        },
        examButtons: {
            elem: 'div',
            className: 'login-buttons',
            parent: 'examBodyBox'
        },
        examProctor: {
            elem: 'input', className: `${configSolidPrimaryButton} login-button`, parent: 'examButtons',
            attrib: { type: 'button', value: translate('CHOOSE_PROCTOR_BUTTON') }
        },
        examGo: {
            elem: 'input', className: `${configSolidPrimaryButton} login-button`, parent: 'examButtons',
            attrib: { type: 'button', value: translate('CHOOSE_EXAM_BUTTON') }
        },
        examBack: {
            elem: 'input', className: `${configSolidPrimaryButton} login-button`, parent: 'examButtons', //style: {marginTop: '4.8rem'},
            attrib: { type: 'button', value: translate('CHOOSE_LOGOUT_BUTTON') }
        },
        examText: {elem: 'div', className: 'login-footer config-user100aa-text', parent: 'examBodyBox'},
    };
}

interface ExamOptionUi {
    examOption: HTMLOptionElement;
    optionText: Text;
}

const examOptionUi = {
    examOption: { elem: 'option' },
    optionText: { elem: 'text', parent: 'examOption' }
};

export interface ChoiceContext {
    parent: HTMLElement;
    badPin: string;
    examPin?: string;
    autoPin?: boolean;
    candidateId: string;
    exams: ExamMap;
    chosenExamId?: string;
    manualStart: boolean;
    proctored: boolean;
}

export interface ChosenExam {
    kind: 'exam';
    examId: ExamId;
    examPin: string;
    autoPin: boolean;
}

export interface Logout {
    kind: 'logout';
}

/*interface StartMessage {
    examid: string;
    pin: string;
    users: {[x:string]:string};
    timestamp: number;
}*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
/*function isStartMessage(x: any): x is StartMessage {
    return x && typeof x === 'object' &&
        typeof x.examid === 'string' &&
        typeof x.pin === 'string' &&
        typeof x.users === 'object' &&
        typeof x.timestamp === 'number';
}*/

interface ExamListChangedMessage {
    users: string[];
    timestamp: number;
}

function isExamListChangedMessage(x: unknown): x is ExamListChangedMessage {
    return isIndexed(x) && Array.isArray(x.users) && typeof x.timestamp === 'number';
}

export class AssignmentViewer {
    private readonly parent: HTMLElement;
    private si: ExamSelectUi;
    private badPin: string;
    private readonly candidateId: string;
    private eventSource: ReconnectingEventSource;
    private manualStart: boolean;
    private exams: ExamMap;
    private usePin?: string;
    private proctored: boolean;

    private makeOpt({name, value = '', disabled = false, selected = false, hidden = false}: {name: string, value?: string, disabled?: boolean, selected?: boolean, hidden?: boolean}) {
        const oi = makeElements(examOptionUi) as ExamOptionUi;
        oi.examOption.value = value;
        oi.optionText.nodeValue = name;
        oi.examOption.disabled = disabled;
        oi.examOption.selected = selected;
        oi.examOption.hidden = hidden;
        this.si.examChoice.appendChild(oi.examOption);
    }

    constructor(context: ChoiceContext) {
        this.manualStart = context.manualStart;
        this.si = makeElements(examSelectUi({cid: context.candidateId})) as ExamSelectUi;
        this.exams = context.exams;
        this.proctored = context.proctored;
        this.candidateId = context.candidateId;
        this.parent = context.parent;
        this.badPin = context.badPin;

        const {started, waiting} = Array.from(context.exams.entries()).reduce((acc, x) => {
            if (!x[1].size) {
                acc.failed.push(x);
            } else if (!context.proctored && x[1].proctored) {
                acc.proctored.push(x);
            } else if (x[1].pin || x[1].state === 'STARTED') {
                acc.started.push(x);
            } else {
                acc.waiting.push(x)
            }
            return acc;
        }, {
            started: new Array<[string, ExamItem]>(),
            waiting: new Array<[string, ExamItem]>(),
            proctored: new Array<[string, ExamItem]>(),
            failed: new Array<[string, ExamItem]>(),
        });

        this.makeOpt({name: translate('CHOOSE_EXAM_HINT'), disabled: true, selected: true, hidden: true});
        if (started.length > 0) {
            this.makeOpt({name: translate('CHOOSE_ALREADY_STARTED'), disabled: true});
            for (const [id, ex] of started) {
                this.makeOpt({name: ex.full_title, value: id, selected: context.chosenExamId === id || (started.length === 1 && waiting.length === 0)});
            }
            if (started.length === 1 && waiting.length === 0) {
                this.usePin = started[0][1]?.pin;
            }
        }
        if (waiting.length > 0) {
            this.makeOpt({name: translate('CHOOSE_WAITING_TO_START'), disabled: true});
            for (const [id, ex] of waiting) {
                this.makeOpt({name: ex.full_title, value: id, selected: context.chosenExamId === id || (started.length === 0 && waiting.length === 1)});
            }
        }

        if (started.length > 0 || waiting.length > 0) {
            this.si.examChoiceLabel.hidden = false;
        } else {
            this.si.examChoiceLabel.hidden = true;
        }

        if (this.badPin) {
            this.si.pinError.innerHTML = this.badPin;
            this.si.pinError.hidden = false;
            this.usePin = undefined;

        } else {
            this.si.pinError.hidden = true;
        }

        if (context.examPin && !context.autoPin) {
            this.si.examPin.value = context.examPin;
        }

        if (started.length + waiting.length === 1) {
            this.si.examPinLabel.hidden = false;
            this.si.examPinText.innerHTML = (((started.length === 1) ? started[0][1].autostart : waiting[0][1].autostart) && !this.manualStart)
                ? translate('CHOOSE_AUTO_PIN_LABEL')
                : translate('CHOOSE_MANUAL_PIN_LABEL');
        } else {
            this.si.examPinLabel.hidden = true;
        }

        this.si.examGo.hidden = Boolean(this.badPin) || !(context.examPin ?? this.usePin);
        this.si.examBack.className = (context.examPin ?? this.usePin) ? `login-button ${configGhostPrimaryButton}` : `login-button ${configSolidPrimaryButton}`;
        this.si.examProctor.hidden = true;
        this.eventSource = new ReconnectingEventSource(new URL('/app/all/events/', window.location.origin));
        //this.eventSource.addEventListener('exam-started', this.handleStartMessage);
        this.eventSource.addEventListener('examlist-changed', this.handleExamlistMessage);
    }

    destroy(): void {
        this.eventSource.removeEventListener('examlist-changed', this.handleExamlistMessage);
        //this.eventSource.removeEventListener('exam-started', this.handleStartMessage);
        this.eventSource.destroy();
        removeChildren(this.parent);
    }

    //private readonly handleStartMessage = (event: MessageEvent<string>): void => {
    //    console.warn('DEPRECATED exam-start EVENT', event);
    //}

    private readonly handleExamlistMessage = async (event: MessageEvent<string>): Promise<void> => {
        try {
            await dbPut('users', 'examCount', 0);
            console.log('SSE_CHANGE', event.data);
            const data = safeJsonParse(event.data);
            if (isExamListChangedMessage(data) && data.users.indexOf(this.candidateId) >= 0) {
                window.location.reload();
            }
        } catch (err) {
            await alertModal(`Message receive failed: ${String(err)}`);
        }
    }

    private readonly handlePin = (): void => {
        this.si.pinError.hidden = true;
        this.usePin = this.exams.get(this.si.examChoice.value)?.pin;
        const pinValid = this.si.examChoice.value && ((!this.badPin && this.usePin) || this.si.examPin.value);
        this.si.examGo.hidden = !pinValid;
        this.si.examBack.className = pinValid
            ? `login-button ${configGhostPrimaryButton}`
            : `login-button ${configSolidPrimaryButton}`;
        this.si.examInstructions.innerHTML = pinValid
            ? translate('CHOOSE_PRESS_START')
            : (this.manualStart || !this.exams.get(this.si.examChoice.value)?.autostart)
                ? translate('CHOOSE_MANUAL_START')
                : translate('CHOOSE_AUTO_START');
    }

    private readonly handleChoice = (event?: Event): void => {
        if (this.si.examChoice.value) {
            const exam = this.exams.get(this.si.examChoice.value);
            if (exam) {
                if (event) {
                    dbPut('users', 'choice', this.si.examChoice.value);
                    this.badPin = '';
                }
                this.usePin = exam.pin;
                const manualStart = this.manualStart || !exam.autostart;
                this.si.examInstructions.innerHTML = manualStart
                    ? translate('CHOOSE_MANUAL_START')
                    : (this.usePin || this.si.examPin.value)
                        ? translate('CHOOSE_PRESS_START')
                        : translate('CHOOSE_AUTO_START');
                this.si.examPinText.innerHTML = manualStart
                    ? translate('CHOOSE_MANUAL_PIN_LABEL')
                    : translate('CHOOSE_AUTO_PIN_LABEL');
                this.si.examPinLabel.hidden = false;
                const pinValid = this.si.examChoice.value && ((!this.badPin && this.usePin) || this.si.examPin.value);
                this.si.examGo.hidden = !pinValid;
                this.si.examBack.className = pinValid ? `login-button ${configGhostPrimaryButton}` : `login-button ${configSolidPrimaryButton}`;
            }
        }
    }

    public async getExamChoice(): Promise<ChosenExam|Logout> {
        const {used, quota} = await getFreeSpace();
        if (used != null && quota != null) {
            this.si.examText.innerHTML = translate('FREE_SPACE', {used, quota});
        }
        return new Promise(async resolve => {
            if (this.si.examChoice.children.length > 1) {
                const choice = await dbGet('users', 'choice');
                if (typeof choice == 'string' && choice) {
                    this.si.examChoice.value = choice;
                }
                this.handleChoice();
                this.si.examPin.oninput = this.handlePin;
                this.si.examPin.onchange = this.handlePin;
                this.si.examChoice.onchange = this.handleChoice;
                this.si.examGo.onclick = (): void => {
                    const examId = this.si.examChoice.value.trim();
                    console.log(`TAKE_EXAM ${examId}`);
                    if (examId) {
                        if (!this.badPin && this.usePin) {
                            this.parent.removeChild(this.si.examSelect);
                            resolve({kind: 'exam', examId, examPin: this.usePin, autoPin: true});
                        } else {
                            const pin = this.si.examPin.value.trim();
                            if (pin) {
                                this.parent.removeChild(this.si.examSelect);
                                resolve({kind: 'exam', examId, examPin: this.si.examPin.value.trim(), autoPin: false});
                            }
                        }
                    }
                };
            } else {
                //this.si.examChoiceLabel.hidden = true;
                //this.si.examGo.hidden = true;
                //this.si.examBack.className = `login-button ${configSolidPrimaryButton}`
            }

            getProctors().then(proctors => {
                if (proctors.examity) {
                    this.si.examInstructions.innerHTML = translate('CHOOSE_PROCTORED_EXAMITY');
                } else {
                    const exam = this.exams.get(this.si.examChoice.value);
                    if (exam) {
                        const manualStart = this.manualStart || !(exam.autostart && !this.badPin);
                        this.si.examInstructions.innerHTML = ((this.usePin || exam.pin) && !this.badPin)
                            ? translate('CHOOSE_PRESS_START')
                            : manualStart
                                ? translate('CHOOSE_MANUAL_START')
                                : translate('CHOOSE_AUTO_START');
                    } else {
                        this.si.examInstructions.innerHTML = (this.si.examChoice.children.length > 1)
                            ? translate('CHOOSE_SELECT_EXAM')
                            : translate('CHOOSE_NO_EXAMS')
                    }
                }

                if (!this.proctored && proctors.examity) {
                    this.si.examProctor.hidden = false;
                    this.si.examProctor.onclick = (): void => {
                        if (proctors.examity) {
                            proctorExamity(proctors.examity);
                        }
                    };
                    this.si.examBack.className = `login-button ${configGhostPrimaryButton}`;
                }
                this.si.examBack.onclick = (): void => {
                    this.parent.removeChild(this.si.examSelect);
                    resolve({kind: 'logout'});
                };
                this.parent.appendChild(this.si.examSelect);
            });
        });
    }
}


